import { Autocomplete, Chip, Stack, TextField } from '@mui/material';
import { FC, SyntheticEvent, useRef } from 'react';
import { useBroadcastGroup } from '../../utils/BroadcastGroupProvider';
import { useOperatingUnits } from 'core/hooks/broadcast';
import { AllCheckbox } from './AllCheckbox';

const OperatingUnitAutocomplete: FC = () => {
	const { setBroadcastGroup, broadcastGroup } = useBroadcastGroup();
	const textFieldRef = useRef<HTMLInputElement>(null);

	const { data: operatingUnits, isLoading, isFetching } = useOperatingUnits(
		broadcastGroup.division.map((division) => division.id)
	);

	const handleOperatingUnitChange = (event: SyntheticEvent, newValue: any[]) => {
		setBroadcastGroup((prev) => ({
			...prev,
			operating_unit: newValue,
		}));
		if (textFieldRef.current) {
			textFieldRef.current.value = '';
		}
	};

	const handleDelete = (operatingUnitToDelete: any) => () => {
		setBroadcastGroup((prev) => ({
			...prev,
			operating_unit: (prev.operating_unit || []).filter(
				(operatingUnit) => operatingUnit.id !== operatingUnitToDelete.id
			),
		}));
	};

	const onAllToggle = (e) => {
		if (e.target.checked) {
			setBroadcastGroup((prev) => ({
				...prev,
				operating_unit: filteredOptions,
			}));
		} else {
			setBroadcastGroup((prev) => ({
				...prev,
				operating_unit: [],
			}));
		}
	};

	const filteredOptions = operatingUnits?.items
		.map((operatingUnit) => ({
			...operatingUnit,
			name: operatingUnit.name,
		}))
		.filter(
			(operatingUnit) =>
				!broadcastGroup.operating_unit?.some(
					(selectedOperatingUnit) => selectedOperatingUnit.id === operatingUnit.id
				)
		);

	const value = operatingUnits?.items?.filter((selectedOU) => {
		if (broadcastGroup.operating_unit.some((ou) => ou.id === selectedOU.id)) {
			return true;
		}
		return false;
	}) || broadcastGroup.operating_unit;

	return (
		<Stack spacing={2} direction="row" alignItems="center">
			<AllCheckbox isDisabled={isLoading || isFetching} onChange={onAllToggle} />
			<Autocomplete
				value={value}
				disabled={isLoading || isFetching}
				multiple
				disablePortal
				disableCloseOnSelect
				onChange={handleOperatingUnitChange}
				id="combo-box-demo"
				options={filteredOptions || []}
				getOptionLabel={(option) => `${option.name}`}
				sx={{ width: '100%' }}
				renderTags={(value: readonly any[], getTagProps) =>
					value.map((option: any, index: number) => (
						<Chip
							key={index}
							variant="outlined"
							label={option.name}
							{...getTagProps({ index })}
							onDelete={handleDelete(option)}
							sx={{ margin: 0.5, backgroundColor: '#2196f3', color: 'white' }}
						/>
					))
				}
				renderInput={(params) => (
					<TextField {...params} inputRef={textFieldRef} label="Operating Units" />
				)}
			/>
		</Stack>
	);
};

export default OperatingUnitAutocomplete;
