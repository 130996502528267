import { FC } from 'react';

// CSS modules
import { GetEmployeeAttendance } from 'core/API/laborsaber';
import Loading from 'core/components/Loading/Loading';
import { getBusinessDay } from 'module/laborsaber/utils/utils';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import moment from 'moment';
import { useQuery } from 'react-query';
import Sections from '../sections.module.scss';

function createMessage(row, action: string, minutes: number) {
	return {
		name: `${row.user.first_name} ${row.user.last_name}`,
		emp_arrived: action === 'left late' ? row.employee_left : row.employee_arrived,
		message: `${minutes} minutes ${action}`,
	};
}

const EmployeeClockEntries: FC = () => {
	const { workplaceId, timezone } = useWorkplacesContext();

	const { data: tableList, isLoading } = useQuery(
		'EmployeeAttendance',
		() => {
			const [firstBusinessDay, lastBusinessDay] = getBusinessDay(timezone);

			return GetEmployeeAttendance({
				workplaceId,
				formatedFirstDay: firstBusinessDay,
				formatedLastDay: lastBusinessDay,
			});
		},
		{
			select: (data) => {
				const attendanceMessages = [];

				data.items.forEach((row) => {
					if (row.employee_arrived !== null || row.employee_left !== null) {
						const timeArrived = moment
							.tz(row.shift.start_at, timezone)
							.diff(moment.tz(row.employee_arrived, timezone), 'minutes');

						const timeLeft = moment
							.tz(row.shift.end_at, timezone)
							.diff(moment.tz(row.employee_left, timezone), 'minutes');

						if (timeArrived <= 0 && timeArrived <= -10) {
							attendanceMessages.push(createMessage(row, 'arrived late', Math.abs(timeArrived)));
						} else if (timeArrived >= 0 && timeArrived >= 10) {
							attendanceMessages.push(createMessage(row, 'arrived early', Math.abs(timeArrived)));
						}

						if (timeLeft >= 0 && timeLeft >= 10) {
							attendanceMessages.push(createMessage(row, 'left early', Math.abs(timeLeft)));
						} else if (timeLeft <= 0 && timeLeft <= -10) {
							attendanceMessages.push(createMessage(row, 'left late', Math.abs(timeLeft)));
						}
					}
				});

				return attendanceMessages;
			},
		}
	);

	return (
		<article
			style={{
				width: '100%',
				height: '100%',
				borderRadius: '8px',
				padding: '24px',
				boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.1)',
				background: '#ffffff',
				gridArea: 'emplClockEntries',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			{isLoading ? (
				<div>
					<Loading />
				</div>
			) : (
				<>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<h4 style={{ paddingLeft: '16px' }}>Employee clock-entries</h4>
					</div>
					<div style={{ flex: 1, overflow: 'auto', flexBasis: '150px' }}>
						<div className={Sections.table}>
							<div className={Sections.tableHeaderThree}>
								<span>Employee</span>
								<span>Time</span>
								<span>Status</span>
							</div>
							<div className={Sections.tableBody}>
								{!tableList.length ? (
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										No data
									</div>
								) : null}
								{tableList.map((row, index) => {
									return (
										<div key={index} className={Sections.tableRowThree}>
											<div>{row.name}</div>
											<div>{moment.tz(row.emp_arrived, timezone).format('hh:mm A')}</div>
											<div>{row.message}</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</>
			)}
		</article>
	);
};

export default EmployeeClockEntries;
