import { Box } from '@mui/material';
import { FormGroupSection } from 'module/documents/components/components';
import { WorkspaceUserAutocomplete } from 'module/documents/tabs/HumanResource/components';

import { FC } from 'react';

const SelectedEmployeeTab: FC = () => {

	return (
		<Box display="flex" flexDirection="column" gap={2}>
			<FormGroupSection>
				<WorkspaceUserAutocomplete />
			</FormGroupSection>
		</Box>
	);
};

export default SelectedEmployeeTab;
