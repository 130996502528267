import { FC } from 'react';
import ParticipantsAutocomplete from '../selects/ParticipantsAutocomplete';
import { BroadcastTabProps } from './tabUtils';

export const DirectMessageTab: FC<BroadcastTabProps> = () => {
	return (
		<>
			<ParticipantsAutocomplete />
		</>
	);
};
