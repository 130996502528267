import { Autocomplete, TextField, Chip } from '@mui/material';
import { useParticipants } from 'core/hooks/broadcast'; // Adjust the import path as needed
import { FC, SyntheticEvent, useRef } from 'react';
import { useBroadcastGroup } from '../../utils/BroadcastGroupProvider';

interface ParticipantsAutocompleteProps {
  multiple?: boolean;
}

const ParticipantsAutocomplete: FC<ParticipantsAutocompleteProps> = ({ multiple = false }) => {
  const { setBroadcastGroup, broadcastGroup } = useBroadcastGroup();
  const textFieldRef = useRef<HTMLInputElement>(null);
  const { data: participants, isLoading, isFetching } = useParticipants();

  const handleParticipantChange = (event: SyntheticEvent, newValue: any) => {
    setBroadcastGroup((prev) => ({
      ...prev,
      participants: newValue ? [newValue] : [],
    }));
    if (textFieldRef.current) {
      textFieldRef.current.value = '';
    }
  };

  const filteredOptions = participants?.map((participant) => ({
    ...participant,
    name: `${participant.first_name} ${participant.last_name}`,
  }));

  const value = multiple
    ? filteredOptions?.filter((option) =>
        broadcastGroup.participants?.some(
          (selectedParticipant) => selectedParticipant.id === option.id
        )
      )
    : filteredOptions?.find((option) =>
        broadcastGroup.participants?.some(
          (selectedParticipant) => selectedParticipant.id === option.id
        )
      );

  return (
    <Autocomplete
      disabled={isLoading || isFetching}
      value={value}
      multiple={multiple}
      disablePortal
      disableCloseOnSelect
      onChange={(event, newValue) => handleParticipantChange(event, newValue)}
      id="participants-autocomplete"
      options={filteredOptions || []}
      // @ts-ignore
      getOptionLabel={(option) => `${option.name}`}
      sx={{ width: '100%' }}
      renderTags={(value: readonly any[], getTagProps) =>
        multiple
          ? value.map((option: any, index: number) => (
              <Chip
                key={index}
                variant="outlined"
                label={option.name}
                {...getTagProps({ index })}
                sx={{ margin: 0.5, backgroundColor: '#2196f3', color: 'white' }}
              />
            ))
          : null
      }
      renderInput={(params) => (
        <TextField {...params} inputRef={textFieldRef} label="Participants" />
      )}
    />
  );
};

export default ParticipantsAutocomplete;
