import { Autocomplete, Chip, Stack, TextField } from '@mui/material';
import { FC, SyntheticEvent, useRef } from 'react';
import { useBroadcastGroup } from '../../utils/BroadcastGroupProvider';
import { useDivision } from 'core/hooks/broadcast';
import { AllCheckbox } from './AllCheckbox';

const DivisionAutocomplete: FC = () => {
	const { setBroadcastGroup, broadcastGroup } = useBroadcastGroup();
	const textFieldRef = useRef<HTMLInputElement>(null);
	const { data: divisions, isLoading, isFetching } = useDivision();

	const handleDivisionChange = (event: SyntheticEvent, newValue: any[]) => {
		setBroadcastGroup((prev) => ({
			...prev,
			division: newValue,
		}));
		if (textFieldRef.current) {
			textFieldRef.current.value = '';
		}
	};

	const handleDelete = (divisionToDelete: any) => () => {
		setBroadcastGroup((prev) => ({
			...prev,
			division: (prev.division || []).filter((division) => division.id !== divisionToDelete.id),
		}));
	};

	const onAllToggle = (e) => {
		if (e.target.checked) {
			setBroadcastGroup((prev) => ({
				...prev,
				division: filteredOptions,
			}));
		} else {
			setBroadcastGroup((prev) => ({
				...prev,
				division: [],
			}));
		}
	};

	const filteredOptions = divisions?.items
		.map((division) => ({
			...division,
			name: division.name,
		}))
		.filter(
			(division) =>
				!broadcastGroup.division?.some((selectedDivision) => selectedDivision.id === division.id)
		);

	const value = divisions?.items?.filter((selectedDivision) => {
		if (broadcastGroup.division.some((division) => division.id === selectedDivision.id)) {
			return true;
		}
		return false;
	}) || broadcastGroup.division;

	return (
		<Stack spacing={2} direction="row" alignItems="center">
			<AllCheckbox isDisabled={isLoading || isFetching} onChange={onAllToggle} />
			<Autocomplete
				disabled={isLoading || isFetching}
				value={value}
				multiple
				disablePortal
				disableCloseOnSelect
				onChange={handleDivisionChange}
				id="combo-box-demo"
				options={filteredOptions || []}
				getOptionLabel={(option) => `${option.name}`}
				sx={{ width: '100%' }}
				renderTags={(value: readonly any[], getTagProps) =>
					value.map((option: any, index: number) => (
						<Chip
							key={index}
							variant="outlined"
							label={option.name}
							{...getTagProps({ index })}
							onDelete={handleDelete(option)}
							sx={{ margin: 0.5, backgroundColor: '#2196f3', color: 'white' }}
						/>
					))
				}
				renderInput={(params) => (
					<TextField {...params} inputRef={textFieldRef} label="Divisions" />
				)}
			/>
		</Stack>
	);
};

export default DivisionAutocomplete;
