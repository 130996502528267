import { ArticleOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { DocumentProvider } from 'module/documents/context';
import { useWorkplaceUser } from 'module/documents/utils';
import { useState } from 'react';
import { CompanyRegistrationStepper } from './CompanyRegistrationStepper';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
	pl: 0,
	width: 'clamp(400px, 80%, 1000px)',
	minHeight: '300px',
};

export const DocumentModal = () => {
	const { data: user, isLoading } = useWorkplaceUser();

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	if (isLoading) {
		return null;
	}

	return (
		<>
			<IconButton onClick={handleOpen} color="primary">
				<ArticleOutlined fontSize="large" />
			</IconButton>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<DocumentProvider defaultUser={user}>
						<CompanyRegistrationStepper />
					</DocumentProvider>
				</Box>
			</Modal>
		</>
	);
};
