import { apiService } from 'core/services/apiService';
import { IPaginatedResponse } from 'types';

export interface Role {
	id: number;
	identifier: string;
	name: string;
	permission: Permission[];
	active: boolean;
	deleted: boolean;
	background_color: string;
	text_color: string;
}

export interface Permission {
	id: number;
	identifier: string;
	name: string;
}

const GetRolesList = async (): Promise<IPaginatedResponse<Role>> => {
	const { ...data } = apiService.responseHandler(
		await apiService.get(`roles`, {
			params: {
				limit: 500,
			},
		})
	);

	return data;
};

export { GetRolesList };
