const generateTimeArray = (date?: string): string[] => {
	const halfHours = ['00', '30'];
	const times = [];

	const preselectedHour = date !== undefined ? parseInt(date?.split(':')[0]) : 0;
	const preselectedMinutes = date !== undefined ? (date?.split(':')[1] === '00' ? 0 : 1) : 0;

	let counter = 0;
	for (let i = preselectedHour; i < 24; i++) {
		for (let j = 0; j < 2; j++) {
			if (counter++ === 0) {
				j = preselectedMinutes;
			}

			let time = i + ':' + halfHours[j];
			if (i < 10) {
				time = '0' + time;
			}
			times.push(time);
		}
	}

	times.push('23:59');
	return times;
};

export default generateTimeArray;
