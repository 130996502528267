import { IGuestConnect } from 'module/guestconnect/interface/IGuestConnect';
import React, { useEffect } from 'react';
import FooterButtons, { IFooterButtons } from '../FooterButtons/FooterButtons';

import * as yup from 'yup';

import style from './PersonalInfo.module.scss';
import { useFormik } from 'formik';

interface IPersonalInfoProps extends IFooterButtons {
	state: IGuestConnect;
	personalInfoHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const phoneRegExp =
	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export default function PersonalInfo({ personalInfoHandler, goNext, goBack }: IPersonalInfoProps): JSX.Element {
	const { errors, touched, values, handleChange, handleBlur, isValid, validateForm } = useFormik({
		initialValues: {
			first_name: '',
			last_name: '',
			email: '',
			phone: '',
		},
		validationSchema: yup.object().shape({
			first_name: yup.string().required('Required'),
			last_name: yup.string().required('Required'),
			email: yup.string().email().required('Required'),
			phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Required'),
		}),
		enableReinitialize: true,
		onSubmit: (values) => {
			// console.log(values);
		},
	});

	// initial value of the form is valid
	// so run this on mount to reset that
	useEffect(() => {
		(() => validateForm())();
	}, []);

	const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		handleChange(e);
		personalInfoHandler(e);
	};

	return (
		<>
			<div className={style.Container}>
				<p className={style.InfoText}>Please complete the questionnaire and receive a token of our appreciation.</p>
				<div className={style.InputRoot}>
					<label htmlFor="first_name">First name (required)</label>
					<input
						type="text"
						id="first_name"
						name="first_name"
						placeholder="John"
						onChange={inputHandler}
						onBlur={handleBlur}
						value={values.first_name}
					/>
					{errors.first_name && touched.first_name ? <p>{errors.first_name}</p> : null}
				</div>
				<div className={style.InputRoot}>
					<label htmlFor="last_name">Last name (required)</label>
					<input
						type="text"
						id="last_name"
						name="last_name"
						placeholder="Doe"
						onChange={inputHandler}
						onBlur={handleBlur}
						value={values.last_name}
					/>
					{errors.last_name && touched.last_name ? <p>{errors.last_name}</p> : null}
				</div>
				<div className={style.InputRoot}>
					<label htmlFor="email">Email (required)</label>
					<input
						type="text"
						id="email"
						name="email"
						placeholder="john.doe@gmail.com"
						onChange={inputHandler}
						onBlur={handleBlur}
						value={values.email}
					/>
					{errors.email && touched.email ? <p>{errors.email}</p> : null}
				</div>
				<div className={style.InputRoot}>
					<label htmlFor="phone">Phone number (required)</label>
					<input
						type="text"
						id="phone"
						name="phone"
						placeholder="Enter phone number"
						onChange={inputHandler}
						onBlur={handleBlur}
						value={values.phone}
					/>
					{errors.phone && touched.phone ? <p>{errors.phone}</p> : null}
				</div>

				<p className={style.InfoText}>
					We will not share your personal information but will use it for analytical purposes, proceeding with the
					questionnaire constitutes your consent
				</p>
			</div>
			<FooterButtons goNext={goNext} goBack={goBack} isDisabled={!isValid} showPrevious={false} label="Next" />
		</>
	);
}
