// hooks/useSchedules.ts
import { useQuery } from 'react-query';
import { GetSchedules } from 'core/API/schedules';
import { MbscCalendarEvent } from '@mobiscroll/react';
import { useToastContext } from 'core/components/Toast/context/ToastContext';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { useAuth } from 'module/auth/context/AuthContext';
import { useMobileDetect } from 'core/hooks/useMobileDetect';

interface UseSchedulesProps {
	fromDate: string;
	toDate: string;
	view: string;
}

export const useSchedules = ({ fromDate, toDate, view }: UseSchedulesProps) => {
	const { workplaceId } = useWorkplacesContext();
	const { showToast } = useToastContext();
	const auth = useAuth();
	const isMobile = useMobileDetect().isMobile();

	const { data, refetch: refetchSchedules } = useQuery(
		['schedule', workplaceId, fromDate, toDate],
		() =>
			GetSchedules({
				workplace: workplaceId,
				from: fromDate,
				to: toDate,
			}),
		{
			refetchInterval: 600000, // 10 min interval for refresh
			enabled: !!fromDate && !!toDate,
			select: (data) => {
				const eventsObj: Record<number, MbscCalendarEvent[]> = {};
				const customEvents: MbscCalendarEvent[] = [];
				const unassignedEvents: MbscCalendarEvent[] = [];

				data.items.forEach((event) => {
					if (event.user === null) {
						unassignedEvents.push(event);
					} else {
						if (!eventsObj[event.user.id]) {
							eventsObj[event.user.id] = [];
						}
						eventsObj[event.user.id].push(event);
					}
				});

				for (const userId in eventsObj) {
					eventsObj[userId].forEach((current, index) => {
						const baseEvent = {
							shift_id: current.id,
							id: current?.user?.id + index,
							resource: current?.user?.id ? current?.user?.id : 0,
							start: current.start_at,
							end: current.end_at,
							title: `${current?.user?.first_name} ${current?.user?.last_name}`,
							published: current?.published,
							scheduled: current.scheduled,
							isOpenShift: false,
							color: current.roles[0]?.background_color,
							background_color: current.roles[0]?.background_color,
							text_color: current.roles[0]?.text_color,
							owner: auth.user.id === current.user.id,
						};

						if (current.scheduled) {
							customEvents.push({ ...baseEvent, editable: false, role: current?.roles[0]?.name });
						} else {
							customEvents.push({ ...baseEvent, editable: true, role: current?.role.name });
						}
					});
				}

				if (isMobile) {
					unassignedEvents.forEach((shift, index) => {
						customEvents.push({
							shift_id: shift.id,
							id: shift?.user ? shift?.user?.id + index : 0 + index,
							resource: shift?.user?.id ? shift?.user?.id : 0,
							start: shift.start_at,
							end: shift.end_at,
							title: `Open shift`,
							editable: false,
							role: shift?.roles[0]?.name,
							published: shift?.published,
							scheduled: shift.scheduled,
							isOpenShift: true,
						});
					});
				}

				return { customEvents, unassignedEvents };
			},
			onError: (e: any) => {
				showToast('error', e.error);
			},
		}
	);

	return {
		events: data?.customEvents || [],
		eventsUnassigned: data?.unassignedEvents || [],
		refetchSchedules,
	};
};
