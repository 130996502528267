import { Box, Button, FormControl, FormLabel, Link, Typography } from '@mui/material';
import { useMe, useWorkspaceResources } from 'core/hooks/broadcast';
import { useDocumentContext } from 'module/documents/context';
import { ChangeEvent, FC, useMemo, useState } from 'react';
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { useEmployeeFormData } from 'module/documents/hooks';

interface WorkspaceUserAutocompleteProps {}

export const WorkspaceUserAutocomplete: FC<WorkspaceUserAutocompleteProps> = () => {
	const { data: usersData } = useWorkspaceResources();
	const { selectedEmployee, setSelectedEmployee } = useDocumentContext();
	const [isChangingUser, setIsChangingUser] = useState(false);

	const { isLoading } = useEmployeeFormData();

	const handleUserChange = (selectedOption: any) => {
		setIsChangingUser(true);

		if (!selectedOption) {
			setSelectedEmployee(null);
			return;
		}

		setSelectedEmployee(usersData[selectedOption.index] || null);

		setTimeout(() => {
			setIsChangingUser(false);
		}, 2000);
	};

	const filteredOptions = useMemo(() => {
		if (!usersData) {
			return [];
		}

		return usersData.map((user, i) => {
			return {
				index: i,
				value: user.user.id,
				email: user.user.email,
				label: `${user.user.first_name} ${user.user.last_name} (${user.user.email})`,
			};
		});
	}, [usersData]);

	return (
		<FormControl component="fieldset" fullWidth>
			<FormLabel
				component="legend"
				sx={{ minHeight: '35px', display: 'flex', alignItems: 'center', gap: '10px' }}
			>
				Select Employee
				{isLoading && <ClipLoader color="#841D80" loading={true} />}
			</FormLabel>
			<Box mt={1}>
				<Select
					className="basic-single"
					classNamePrefix="select"
					defaultValue={
						selectedEmployee
							? {
									value: selectedEmployee.id,
									email: selectedEmployee.user.email,
									label: `${selectedEmployee.user.first_name} ${selectedEmployee.user.last_name} (${selectedEmployee.user.email})`,
								}
							: null
					}
					isLoading={isLoading || isChangingUser}
					onChange={handleUserChange}
					isClearable={true}
					isSearchable={true}
					name="employees"
					placeholder="Select Employee"
					options={filteredOptions}
					styles={{
						control: (baseStyles, state) => ({
							...baseStyles,
							borderColor: state.isFocused ? 'grey' : 'red',
						}),
						option: (baseStyles, _state) => {
							return baseStyles;

							// const employeHasExistingForm = employeesWithExistingForm.has(state.data?.email);
							// return {
							// 	...baseStyles,
							// 	color: employeHasExistingForm ? 'green' : 'black',
							// 	fontWeight: employeHasExistingForm ? '900' : '400',
							// };
						},
					}}
				/>
			</Box>
		</FormControl>
	);
};

interface FileUploadProps {
	label?: string;
	existingFile?: Record<any, any>;
	onFileChange?: (file: File) => void;
}

export const FileUploadField: FC<FileUploadProps> = ({ existingFile, label, onFileChange }) => {
	const [selectedFile, setSelectedFile] = useState<File | null>(null);

	const { selectedEmployee } = useDocumentContext();

	const { data: me } = useMe();

	const isActiveUserSelected = selectedEmployee.user.id === me.id;

	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files.length > 0) {
			const file = event.target.files[0];
			setSelectedFile(file);
			onFileChange(file);
		}
	};

	return (
		<Box display="flex" flexDirection="column" alignItems="flex-start" gap={2} mb={2}>
			{label && <Typography variant="h6">{label}</Typography>}

			{existingFile && (
				<Link href={process.env.REACT_APP_URL + existingFile?.path} target="_blank">
					<img
						src={process.env.REACT_APP_URL + existingFile?.path}
						alt={existingFile?.name}
						style={{ marginBottom: 20, maxHeight: '200px' }}
					/>
				</Link>
			)}

			<input
				accept=".jpg,.jpeg,.png,.pdf,.docx"
				style={{ display: 'none' }}
				id="file-upload-input"
				type="file"
				onChange={handleFileChange}
			/>
			<label htmlFor="file-upload-input">
				<Button
					variant="contained"
					component="span"
					startIcon={<CloudUploadIcon />}
					sx={{ marginRight: 2 }}
					disabled={!isActiveUserSelected}
				>
					Choose File
				</Button>
				{selectedFile && (
					<Typography variant="body1">{existingFile.name || selectedFile.name}</Typography>
				)}
			</label>
		</Box>
	);
};
