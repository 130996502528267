import {
	Backdrop,
	Button,
	Card,
	CardActions,
	CardContent,
	Modal,
	TextField,
	Typography,
} from '@mui/material';
import { BroadcastSettings, BroadcastType, createBroadcastGroup } from 'core/API/broadcast';
import { useAuth } from 'module/auth/context/AuthContext';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { Flex } from 'shared/components/Flex.tsx/Flex';
// import { getBroadcastSettings } from '../utils/chat.utils';
import ChatCreateTabs from './ChatCreateTabs';

import { Fade } from 'core/components/Fade/Fade';
import { BroadcastGroup, useBroadcastGroup } from '../utils/BroadcastGroupProvider';
import { DirectMessageTab } from './tabs/DirectMessageTab';

import { generateGroupName } from '../utils/chat.utils';
import { GroupTab } from './tabs/GroupTab';
import SelectedItemsDisplay from './utils/SelectedItemsDisplay';

const style = {
	position: 'absolute' as const,
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 'min(100%, 1200px)',
	height: 'min(100%, 700px)',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	overflow: 'scroll',
	p: 4,
};

interface CreateGroupModalProps {
	children: (handleOpen: () => void) => React.ReactNode;
}

export const CreateGroupModal: React.FC<CreateGroupModalProps> = ({ children }) => {
	const [open, setOpen] = React.useState(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => {
		resetBroadcastGroup();
		setOpen(false);
	};

	const queryClient = useQueryClient();
	const auth = useAuth();

	const { broadcastGroup, setBroadcastGroup, resetBroadcastGroup } = useBroadcastGroup();

	const handleGroupNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newGroupName = e.target.value;

		setBroadcastGroup((prev) => ({
			...prev,
			group_name: newGroupName,
		}));
	};

	const determineBroadcastType = () => {
		if (broadcastGroup.organization?.length) return 'organization';
		if (broadcastGroup.division?.length) return 'division';
		if (broadcastGroup.operating_unit?.length) return 'operating_unit';
		return 'workplace';
	};

	const handleCreateGroup = async () => {
		let roles = broadcastGroup.role || [];

		roles = [...roles, auth.role[0]];

		const newGroup: Partial<BroadcastGroup> = {
			...broadcastGroup,
			// set group name
			group_name: broadcastGroup.group_name || generateGroupName(broadcastGroup),
			participants: broadcastGroup.participants || [],
			...getBroadcastSettings(determineBroadcastType()),
			role: roles,
			// workplace: [
			// 	{
			// 		id: workplaceId,
			// 	},
			// ],
		};

		try {
			await createBroadcastGroup(newGroup);
			queryClient.invalidateQueries('broadcast-groups');
			resetBroadcastGroup();
			handleClose();
		} catch (error) {
			console.error('Error creating group:', error);
		}
	};

	return (
		<React.Fragment key={open ? 'o' : 'c'}>
			{children(handleOpen)}
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				sx={{}}
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						TransitionComponent: Fade,
					},
				}}
			>
				<Flex p={3}>
					<Card sx={style}>
						<CardContent sx={{}}>
							<Typography gutterBottom variant="h5" component="div">
								New Broadcast
							</Typography>
							<SelectedItemsDisplay />
							<TextField
								label="Group Name"
								value={broadcastGroup.group_name}
								helperText={!broadcastGroup.group_name ? 'Group Name is optional' : ''}
								onChange={handleGroupNameChange}
								sx={{ width: '100%', marginBottom: 2 }}
							/>
							<ChatCreateTabs>
								<DirectMessageTab />
								<GroupTab />
								{/* <WorkplaceTab />
								<DivisionTab />
								<OperatingUnitTab /> */}
							</ChatCreateTabs>
						</CardContent>
						<CardActions>
							<Button size="large" variant="contained" color="primary" onClick={handleCreateGroup}>
								Start Broadcast
							</Button>
						</CardActions>
					</Card>
				</Flex>
			</Modal>
		</React.Fragment>
	);
};

export const getBroadcastSettings = (type: BroadcastType): BroadcastSettings => {
	const settings = {
		organization_broadcast: false,
		division_broadcast: false,
		operating_unit_broadcast: false,
		workplace_broadcast: false,
	};

	if (type === 'organization') {
		settings.organization_broadcast = true;
	} else if (type === 'division') {
		settings.division_broadcast = true;
	} else if (type === 'operating_unit') {
		settings.operating_unit_broadcast = true;
	} else if (type === 'workplace') {
		settings.workplace_broadcast = true;
	}

	return settings;
};
