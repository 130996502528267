import { Box, Typography } from '@mui/material';
import {
	DelayButton,
	FormAlert,
	FormGroupSection,
	SignAndSubmit,
} from 'module/documents/components/components';
import DynamicFormComponent, {
	FieldRenderer,
	GridWrapper,
} from 'module/documents/components/formTabs/DynamicFormComponent';
import { EmployerFields } from 'module/documents/components/formTabs/EmployerFields';
import { useDocumentContext } from 'module/documents/context';
import {
	CommonFieldsFormIndexed,
	FullTimeVariableTimeForm,
	IUnderstandFormData,
} from 'module/documents/data';
import { filterFields } from 'module/documents/utils';

const step1Fields = filterFields(CommonFieldsFormIndexed, {
	include: ['first_name', 'middle_initial', 'last_name'],
});

export const FTEForm = () => {
	const { formik, isEmployer } = useDocumentContext();

	const handleSubmit = () => {
		console.log();
	};

	return (
		<>
			<Box mb={2}>
				<Typography variant="h4" mb="24px">
					{FullTimeVariableTimeForm.title}
				</Typography>
			</Box>

			<Box display="flex" flexDirection="column" gap={2}>
				<FormGroupSection>
                    <FormAlert>
                        Choose one of the following options
                    </FormAlert>
					<DynamicFormComponent>
						{FullTimeVariableTimeForm.fields.map((field, index) => (
							<GridWrapper key={index} field={field} index={index}>
								<FieldRenderer field={field} formik={formik} />
							</GridWrapper>
						))}
					</DynamicFormComponent>
				</FormGroupSection>
				<FormGroupSection>
					<DynamicFormComponent>
						{step1Fields.map((field, index) => (
							<GridWrapper key={index} field={field} index={index}>
								<FieldRenderer field={field} formik={formik} />
							</GridWrapper>
						))}
					</DynamicFormComponent>
				</FormGroupSection>
			</Box>

			<Box paddingY="24px">
				<SignAndSubmit onSubmit={handleSubmit} formData={IUnderstandFormData} />
			</Box>

			<FormGroupSection show={isEmployer}>
				<FormGroupSection
					title="Employer Information"
					isEmployer={true}
					marginTop="24px"
					marginBottom="12px"
				>
					<EmployerFields fieldValues={['employer_title']} />
				</FormGroupSection>
				<Box paddingY="8px">
					<DelayButton
						variant="contained"
						onClick={() => {
							formik.setFieldValue('signature_approved', true);
						}}
						style={{ width: 'max-content' }}
					>
						Employeer sign
					</DelayButton>
				</Box>
			</FormGroupSection>
		</>
	);
};
