import { Autocomplete, Chip, Stack, TextField } from '@mui/material';
import { useRoles } from 'core/hooks/broadcast';
import { FC, SyntheticEvent, useRef } from 'react';
import { useBroadcastGroup } from '../../utils/BroadcastGroupProvider';
import { AllCheckbox } from './AllCheckbox';


const RolesAutocomplete: FC = () => {
	const { setBroadcastGroup, broadcastGroup } = useBroadcastGroup();
	const textFieldRef = useRef<HTMLInputElement>(null);
	const { data: roles, isLoading, isFetching } = useRoles();

	const handleRoleChange = (event: SyntheticEvent, newValue: any[]) => {
		setBroadcastGroup((prev) => ({
			...prev,
			role: newValue,
		}));
		if (textFieldRef.current) {
			textFieldRef.current.value = '';
		}
	};

	const handleDelete = (roleToDelete: any) => () => {
		setBroadcastGroup((prev) => ({
			...prev,
			role: (prev.role || []).filter((role) => role.id !== roleToDelete.id),
		}));
	};

	const onAllToggle = (e) => {
		if (e.target.checked) {
			setBroadcastGroup((prev) => ({
				...prev,
				role: filteredOptions,
			}));
		} else {
			setBroadcastGroup((prev) => ({
				...prev,
				role: [],
			}));
		}
	};

	const filteredOptions = roles?.items
		.map((role) => {
			return {
				...role,
				name: role.name,
			};
		})
		.filter((role) => !broadcastGroup.role?.some((selectedRole) => selectedRole.id === role.id));

	return (
		<Stack spacing={2} direction="row" alignItems="center">
			<AllCheckbox isDisabled={isLoading || isFetching} onChange={onAllToggle} />
			<Autocomplete
				disabled={isLoading || isFetching}
				value={broadcastGroup.role}
				multiple
				disablePortal
				disableCloseOnSelect
				onChange={(event, newValue) => handleRoleChange(event, newValue)}
				id="combo-box-demo"
				options={filteredOptions || []}
				getOptionLabel={(option) => `${option.name}`}
				sx={{ width: '100%' }}
				renderTags={(value: readonly any[], getTagProps) =>
					value.map((option: any, index: number) => (
						<Chip
							key={index}
							variant="outlined"
							label={option.name}
							{...getTagProps({ index })}
							onDelete={handleDelete(option)}
							sx={{ margin: 0.5, backgroundColor: '#2196f3', color: 'white' }}
						/>
					))
				}
				renderInput={(params) => <TextField {...params} inputRef={textFieldRef} label="Roles" />}
			/>
		</Stack>
	);
};

export default RolesAutocomplete;
