import { apiService } from 'core/services/apiService';
import { ISchedule } from 'module/schedule/interfaces/ScheduleInterface';

export interface IConfig {
	workplace: number;
	from: string;
	to: string;
}

const GetSchedules = async (config: IConfig): Promise<ISchedule> => {
	const { ...data } = apiService.responseHandler(
		await apiService.get(`schedules?workplace=${config.workplace}&from=${config.from}&to=${config.to}&limit=500`)
	);

	return data;
};

export { GetSchedules };
