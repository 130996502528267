import { Shift } from 'module/laborsaber/utils/types';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import moment from 'moment';
import { FC } from 'react';
import { Flex } from 'shared/components/Flex.tsx/Flex';

import { DeleteShift } from 'core/API/shifts';
import { useToastContext } from 'core/components/Toast/context/ToastContext';
import { useMutation } from 'react-query';

interface IConflictResolver {
	data: Array<Shift>;
}

export const ConflictResolver: FC<IConflictResolver> = ({ data }) => {
	const { timezone } = useWorkplacesContext();
	const { showToast } = useToastContext();

	const { mutate: deleteShift, isLoading: loadingDeleteShift } = useMutation(DeleteShift, {
		onSuccess: (data) => {
			showToast('success', 'Shift successfully deleted!');
		},
		onError: () => {
			// showToast('error');
		},
	});

	const removeShift = (shiftId: number) => {
		deleteShift(shiftId);
	};

	return (
		<Flex flexDirection="column" padding="12px 8px" gap="16px">
			{data?.map((event) => {
				const start = moment(event.start).tz(timezone).utc().format('HH:mm');
				const end = moment(event.end).tz(timezone).utc().format('HH:mm');

				return (
					<Flex key={event.id} gap="12px" justifyContent="space-between">
						<span>
							{event.title} - {event.role} / {event.id} - {start} - {end}
						</span>

						<button
							style={{
								background: 'var(--color-error)',
								color: 'white',
								width: '24px',
								height: '24px',
								borderRadius: '50%',
								border: '1px solid var(--color-error)',
								padding: '4px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
							}}
							disabled={loadingDeleteShift}
							onClick={() => removeShift(event.shift_id)}
						>
							X
						</button>
					</Flex>
				);
			})}
		</Flex>
	);
};
