import { useState } from 'react';

interface iUseModal {
	open: boolean;
	close: boolean;
	openModal: () => void;
	closeModal: () => void;
}

const useModal = (): iUseModal => {
	const [open, onOpenModal] = useState(false);
	const [close, onCloseModal] = useState(false);

	const openModal = () => {
		onOpenModal(true);
	};

	const closeModal = () => {
		onCloseModal(true);
		onOpenModal(false);
	};

	return { open, close, openModal, closeModal };
};

export default useModal;
