import { FC } from 'react';

interface ISvg {
	spriteUrl: string;
	className?: string;
	onClick?: () => void;
}

const SvgIcon: FC<ISvg> = ({ spriteUrl, className, ...props }) => {
	return (
		<svg className={className} {...props}>
			<use href={`${spriteUrl}#icon`} />
		</svg>
	);
};

export default SvgIcon;
