import { FC, useState } from 'react';

import { useMutation, useQuery } from 'react-query';
import { GetOneShift, PutOneShift } from 'core/API/shifts';
import moment, { utc } from 'moment';
import { IDays } from 'module/schedule/interfaces/DaysInterface';
import { useToastContext } from 'core/components/Toast/context/ToastContext';

import Loading from 'core/components/Loading/Loading';

// CSS modules
import Buttons from 'styles/buttons.module.scss';
import Forms from 'styles/forms.module.scss';
import useModal from 'core/hooks/useModal';

import CenterModal from 'core/components/Modal/CenterModal';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';

interface IAvailableShiftModal {
	closeModal: () => void;
	requestData: any;
}

const AvailableShiftModal: FC<IAvailableShiftModal> = (props) => {
	const { showToast } = useToastContext();
	const { timezone } = useWorkplacesContext();

	const { open: isModalOpen, openModal, closeModal } = useModal();

	const [days, setDays] = useState<IDays[]>([
		{ id: 1, label: 'M', active: false },
		{ id: 2, label: 'T', active: false },
		{ id: 3, label: 'W', active: false },
		{ id: 4, label: 'T', active: false },
		{ id: 5, label: 'F', active: false },
		{ id: 6, label: 'S', active: false },
		{ id: 7, label: 'S', active: false },
	]);

	const [week, setWeek] = useState<string>('');
	const [from, setFrom] = useState<string>('');
	const [to, setTo] = useState<string>('');
	const [note, setNote] = useState<string>('');

	const { data: shiftData, isLoading: shiftLoading } = useQuery(
		['shift-one', props?.requestData?.shift_id],
		() => GetOneShift(props.requestData.shift_id!),
		{
			onSuccess: (data) => {
				const shiftDate = new Date(data.start_at);

				const newArrDays = days.map((value) => {
					if (value.id === shiftDate.getDay()) {
						// disable select days in past
						value.active = !value.active;

						return {
							...value,
						};
					}

					return value;
				});

				setDays(newArrDays);

				const weekString = `${utc(data.start_at).format('ddd D MMMM YYYY')} - ${utc(data.end_at).format(
					'ddd D MMMM YYYY'
				)}`;

				const fromString = moment(data.start_at).tz(timezone).format('H:mm');
				const toString = moment(data.end_at).tz(timezone).format('H:mm');

				setNote(data.note);
				setWeek(weekString);
				setFrom(fromString);
				setTo(toString);
			},
		}
	);

	const { mutate: updateOneShift, isLoading: loadingUpdateOneShift } = useMutation(PutOneShift);

	const approveHandler = () => {
		if (props.requestData.overlap) {
			openModal();
		} else {
			updateShift();
		}
	};

	const updateShift = () => {
		const { id, time_card_id, ...rest } = shiftData!;
		updateOneShift(
			{
				id,
				body: {
					...rest,
					scheduled: true,
					note,
					time_card_id: parseInt(time_card_id),
				},
			},
			{
				onSuccess: () => {
					showToast('success', 'Request aproved!');
					props.closeModal();
				},
				onError: () => {
					showToast('error');
					props.closeModal();
				},
			}
		);
	};

	const denyHandler = () => {
		const { id, time_card_id, ...rest } = shiftData!;
		updateOneShift(
			{
				id,
				body: {
					...rest,
					denied: true,
					note,
					time_card_id: parseInt(time_card_id),
				},
			},
			{
				onSuccess: () => {
					showToast('success', 'Request denied!');
					props.closeModal();
				},
				onError: () => {
					showToast('error');
					props.closeModal();
				},
			}
		);
	};

	return shiftLoading || loadingUpdateOneShift ? (
		<Loading />
	) : (
		<>
			<div className={Forms.formTabGroup}>
				<p className={Forms.formTabGroupItemActive}>Preferred</p>
			</div>
			<div className={Forms.formGroup}>
				<p className={Forms.formLabel}>Employee who made request</p>
				<p className={Forms.formLockedText}>
					{shiftData?.user.first_name} {shiftData?.user.last_name}
				</p>
			</div>
			<div className={Forms.formGroup}>
				<p className={Forms.formLabel}>Week</p>
				<p className={Forms.formLockedText}>{week}</p>
			</div>
			<div className={Forms.formGroup}>
				<p className={Forms.formLockedText}>Available all day</p>
			</div>
			<div className={Forms.formGroupSplit}>
				<div className={Forms.formGroup}>
					<p className={Forms.formLabel}>From</p>
					<p className={Forms.formLockedText}>{from}</p>
				</div>
				<div className={Forms.formGroup}>
					<p className={Forms.formLabel}>To</p>
					<p className={Forms.formLockedText}>{to}</p>
				</div>
			</div>
			<div className={Forms.formGroup}>
				<label htmlFor="days" className={Forms.formLabel}>
					Days
				</label>
				<div className={Forms.formChecker}>
					{days.map((value, index) => (
						<div
							id="days"
							className={`${Forms.formCheckerItem} ${value.active ? Forms.formCheckerItemActive : null}`}
							key={index}
						>
							<h5>{value.label}</h5>
						</div>
					))}
				</div>
			</div>
			<div className={Forms.formGroup}>
				<p className={Forms.formLabel}>Status</p>
				<p className={Forms.formLockedText}>Waiting for approval</p>
			</div>
			<div className={Forms.formGroup}>
				<label htmlFor="textarea" className={Forms.formLabel}>
					Note
				</label>
				<textarea
					className={Forms.formTextarea}
					name="note"
					id="textarea"
					placeholder="Leave a note for employee"
					maxLength={160}
					value={note}
					onChange={(e) => setNote(e.target.value)}
				></textarea>
			</div>
			<div className={Forms.formFooterSplit}>
				<button className={Buttons.btnSuccess} onClick={approveHandler}>
					Approve
				</button>
				<button className={Buttons.btnError} onClick={denyHandler}>
					Deny
				</button>
			</div>
			{isModalOpen ? (
				<CenterModal
					title="Conflict warning"
					close={() => {
						closeModal();
					}}
					render={() => (
						<div>
							<h5>If accepted, this shift will cause a conflict with an already existing shift on the same user</h5>
							<div className={Forms.formFooterSplit}>
								<button className={Buttons.btnSuccess} onClick={updateShift}>
									Continue
								</button>
								<button className={Buttons.btnError} onClick={closeModal}>
									Back
								</button>
							</div>
						</div>
					)}
				/>
			) : null}
		</>
	);
};

export default AvailableShiftModal;
