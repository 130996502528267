import axios, { AxiosInstance } from 'axios';

export abstract class HttpClient {
	private static instance: AxiosInstance;

	public static getInstance(): AxiosInstance {
		if (!HttpClient.instance) {
			HttpClient.instance = axios.create({
				baseURL: process.env.REACT_APP_API_URL,
				timeout: 120000,
				headers: {
					'app-type': 'full',
				},
			});
		}
		return HttpClient.instance;
	}
}
