import { ButtonHTMLAttributes, FC, useState } from 'react';
import { ReactComponent as HappyFace } from 'assets/icons/HappyFace.svg';
import { ReactComponent as SmileFace } from 'assets/icons/SmileFace.svg';
import { ReactComponent as AverageFace } from 'assets/icons/AverageFace.svg';
import { ReactComponent as PoorFace } from 'assets/icons/PoorFace.svg';

import style from './QuestionComp.module.scss';
import { GuestConnectField } from 'module/guestconnect/interface/IGuestConnect';
import FooterButtons, { IFooterButtons } from '../FooterButtons/FooterButtons';
import { VoteLevel } from './QuestionComp.enum';
import { Flex } from 'shared/components/Flex.tsx/Flex';

interface QuestionCompProps extends IFooterButtons {
	value: GuestConnectField;
	numberOfQuestion: number;
	questionsLength: number;
	questionHandler: (identifier: string, answer: string) => void;
}

interface VotingButtonProps {
	answer: string;
	identifier: VoteLevel;
	icon: JSX.Element;
	onClick: (identifier: VoteLevel) => void;
}

const VotingButton: FC<VotingButtonProps> = ({ answer, identifier, icon, onClick }) => {

	return (
		<button
			className={`${style.SelectButtons} ${answer === identifier ? style.SelectedButtonColor : null}`}
			onClick={() => {
				onClick(identifier);
			}}
		>
			{icon}
			<p>{identifier}</p>
		</button>
	);
};

export default function QuestionComp({
	value,
	numberOfQuestion,
	questionsLength,
	questionHandler,
	goNext,
	goBack,
}: QuestionCompProps): JSX.Element {
	const [answer, setAnswer] = useState<VoteLevel>(value.answer as VoteLevel);

	const onVoteChange = (identifier: VoteLevel) => {
		setAnswer(identifier);
		questionHandler(value.identifier, identifier);
	};

	return (
		<>
			<div className={style.Container}>
				<div className={style.TextInfo}>
					<div className={style.firstColumn}>
						<Flex alignItems="center" justifyContent="space-between">
							<p className={style.infoSmall}>Your opinion is important to us!</p>
							<div className={style.secondColumn}>
								{numberOfQuestion}/{questionsLength}
							</div>
						</Flex>
						<p className={style.infoBig}>{value.label}</p>
					</div>
				</div>

				<VotingButton identifier={VoteLevel.Excellent} answer={answer} icon={<HappyFace />} onClick={onVoteChange} />
				<VotingButton identifier={VoteLevel.Good} answer={answer} icon={<SmileFace />} onClick={onVoteChange} />
				<VotingButton identifier={VoteLevel.Average} answer={answer} icon={<AverageFace />} onClick={onVoteChange} />
				<VotingButton identifier={VoteLevel.Poor} answer={answer} icon={<PoorFace />} onClick={onVoteChange} />
			</div>
			<FooterButtons
				goNext={goNext}
				goBack={goBack}
				isDisabled={answer === VoteLevel.None}
				showPrevious={numberOfQuestion !== 1}
				label="Next"
			/>
		</>
	);
}
