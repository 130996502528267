import { ProvideAuth } from 'module/auth/context/AuthContext';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

// react query setup
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// import styles
import { ToastContextProvider } from 'core/components/Toast/context/ToastContext';
import { WorkplacesContextProvider } from 'module/workplaces/context/WorkplacesContext';
import 'rsuite/dist/rsuite.min.css';
import './index.scss';
import './styles/chat.scss';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { ThemeProvider } from '@mui/material';
import { theme } from 'styles/mui/theme';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			suspense: false,
			// retryDelay: 60 * 1000,
		},
	},
});

process.env.REACT_APP_SENTRY_ENV === 'staging' &&
	Sentry.init({
		dsn: 'https://598ff86a1059408b8b90596b377717b2@sentry.q-tests.com/49',
		integrations: [new Integrations.BrowserTracing()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<ToastContextProvider>
				<ProvideAuth>
					<QueryClientProvider client={queryClient}>
						<WorkplacesContextProvider>
							{/* <BroadcastInitProvider> */}
								<App />
							{/* </BroadcastInitProvider> */}
							<ReactQueryDevtools initialIsOpen={false} />
						</WorkplacesContextProvider>
					</QueryClientProvider>
				</ProvideAuth>
			</ToastContextProvider>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
