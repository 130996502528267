import { PostGuestConnect } from 'core/API/workplaces';
import Loading from 'core/components/Loading/Loading';
import { IGuestConnect } from 'module/guestconnect/interface/IGuestConnect';
import { IGuestConnectPost } from 'module/guestconnect/interface/IGuestConnectPost';
import { IurlParams } from 'module/guestconnect/page/GuestConnectContainer';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router';
import ThankYou from '../ThankYou/ThankYou';

import welcomeStyle from '../Welcome/Welcome.module.scss';
import { sanitizeStringComment } from 'module/guestprotect/utils';

interface SubmitProps {
	state: IGuestConnect;
	welcome: string;
}

export default function Submit({ state, welcome }: SubmitProps): JSX.Element {
	const { workplaceIdentifier, domainIdentifier }: IurlParams = useParams();

	const [isSubmited, setIsSubmited] = useState(false);

	const { mutate, isLoading, data } = useMutation(PostGuestConnect);

	// TODOT: refactor please
	useEffect(() => {
		const sendObj: Partial<IGuestConnectPost> = {};

		state.guest_connect_basic_field.forEach((value) => {
			if (value.answer !== '') {
				sendObj[value.identifier] = value.answer;
			}
		});

		sendObj.comment = sanitizeStringComment(state.comment);
		sendObj.reward = state.guest_connect_reward.filter((value) => value.selected);

		sendObj.additional_field = [...state.guest_connect_additional_field];

		mutate(
			{
				workplaceIdentifier: workplaceIdentifier,
				domainIdentifier: domainIdentifier,
				body: sendObj,
			},
			{
				onSuccess: (data) => {
					setIsSubmited(true);
				},
			}
		);
	}, []);

	return (
		<>
			{welcome === 'withoutInfo' ? (
				<ThankYou />
			) : isLoading ? (
				<Loading />
			) : isSubmited ? (
				<>
					<p className={welcomeStyle.InfoText} style={{ fontSize: '18px' }}>
						Congratulations, here is your reward coupon. Please show it to the staff.
					</p>
					<img src={process.env.REACT_APP_URL + data.qr_code} alt="guest_protect_logo" style={{ marginBottom: 20 }} />
					<h4>{data.workplace.name}</h4>
					<h4>{data.domain.name}</h4>
					<h4>{data.reward.map((value) => value.label)}</h4>
				</>
			) : null}
		</>
	);
}
