import {
	getBroadcastGroups,
	getDivisions,
	getOperatingUnits,
	getWorkplaces,
} from 'core/API/broadcast';
import { GetDepartmentsList } from 'core/API/departments';
import { fetchHRForm } from 'core/API/documents';
import { GetRolesList } from 'core/API/roles';
import { GetUsersList } from 'core/API/users';
import { GetAllWorkplaceList } from 'core/API/workplaces';
import Loading from 'core/components/Loading/Loading';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { FC, ReactNode, memo } from 'react';
import { useQuery } from 'react-query';

const PreloadComp: FC<{ children: ReactNode }> = ({ children }) => {
	const { workplaceId } = useWorkplacesContext();

	const { isLoading: isLoadingDepartments } = useQuery(['departments'], GetDepartmentsList, {
		staleTime: Infinity,
	});

	const { isLoading: isLoadingRoles } = useQuery(['roles'], GetRolesList, {
		staleTime: Infinity,
	});

	useQuery(['broadcast/workplaces'], () => getWorkplaces([]), {
		staleTime: Infinity,
	});

	useQuery('broadcast-groups', () => getBroadcastGroups(workplaceId, 20), {
		select: (data) => {
			return data;
		},
	});

	useQuery(['broadcast/roles'], GetRolesList, {
		staleTime: Infinity,
	});

	useQuery(['broadcast/divisions'], getDivisions, {
		staleTime: Infinity,
	});

	useQuery(['broadcast/operating-units'], () => getOperatingUnits([]), {
		staleTime: Infinity,
	});

	// useCachedQueryData<DocsHRResponse>("employee-form/hr")
	useQuery(['employee-form/hr'], () => fetchHRForm(), {
		staleTime: Infinity,
	});

	const { isLoading: isLoadingWorkplaces } = useQuery(['workplaces-all'], GetAllWorkplaceList, {
		staleTime: Infinity,
	});

	const { isLoading: isLoadingUsers } = useQuery(
		['users', workplaceId],
		() => GetUsersList(workplaceId),
		{
			enabled: workplaceId !== 0,
		}
	);

	return (
		<>
			{isLoadingDepartments || isLoadingRoles || isLoadingWorkplaces || isLoadingUsers ? (
				<Loading />
			) : (
				children
			)}
		</>
	);
};
// todo: interesting hack
export default memo(PreloadComp);
