import { apiService } from 'core/services/apiService';
import { IDepartments } from 'module/preload/interfaces/DepartmentsInterface';

export interface IWorkplacesDepartments {
	department: Department;
}

export interface Department {
	id: number;
	name: string;
	roles: Role[];
}

export interface Role {
	id: number;
	identifier: string;
	name: string;
}

const GetDepartmentsList = async (): Promise<IDepartments> => {
	const { ...data } = apiService.responseHandler(await apiService.get(`departments`));

	return data;
};

const GetDepartmentsListOnWorkplace = async (
	workplaceId: number
): Promise<IWorkplacesDepartments[]> => {
	const { ...data } = apiService.responseHandler(
		await apiService.get(`workplace-departments/${workplaceId}`)
	);

	// conver object to array
	const resultArray = Object.keys(data).map(function (personNamedIndex) {
		const person = data[personNamedIndex];
		return person;
	});

	return resultArray;
};

export { GetDepartmentsList, GetDepartmentsListOnWorkplace };
