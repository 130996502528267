import { GetEmployeeAttendance, GetLaborsaborCalculation, GetSalesInfo } from 'core/API/laborsaber';
import { SalesNumber } from '../interface/Sales';
import { getBusinessDay } from '../utils/utils';

export const getSalesInfo = async (workplaceId, timezone) => {
	const [firstBusinessDay, lastBusinessDay, activeTime] = getBusinessDay(timezone);

	const returnData = await GetSalesInfo({
		workplaceId,
		formatedFirstDay: firstBusinessDay,
		formatedLastDay: lastBusinessDay,
		activeTime,
	});

	const situationRoomResp = await GetLaborsaborCalculation({
		workplaceId,
		formatedFirstDay: firstBusinessDay,
		formatedLastDay: lastBusinessDay,
	});

	const employeeAttendance = await GetEmployeeAttendance({
		workplaceId,
		formatedFirstDay: firstBusinessDay,
		formatedLastDay: lastBusinessDay,
	});

	const FormatedData: SalesNumber = {
		daily_labor_cost: {
			today: {
				actual_labor_cost: parseFloat(returnData.daily_labor_cost.today.actual_labor_cost),
				mabel_labor_savings: parseFloat(returnData.daily_labor_cost.today.mabel_labor_savings),
				projected_labor_cost: parseFloat(returnData.daily_labor_cost.today.projected_labor_cost),
			},
			last_week: {
				actual_labor_cost: parseFloat(returnData.daily_labor_cost.last_week.actual_labor_cost),
				mabel_labor_savings: parseFloat(returnData.daily_labor_cost.last_week.mabel_labor_savings),
				projected_labor_cost: parseFloat(returnData.daily_labor_cost.last_week.projected_labor_cost),
			},
		},
		sales: {
			today: {
				hourly_labor_cost: parseFloat(returnData.sales.today.hourly_labor_cost),
				net_sales: parseFloat(returnData.sales.today.net_sales),
			},
			last_week: {
				hourly_labor_cost: parseFloat(returnData.sales.last_week.hourly_labor_cost),
				net_sales: parseFloat(returnData.sales.last_week.net_sales),
			},
		},
		situation_room: situationRoomResp,
		employee_attendance: employeeAttendance,
	};

	return FormatedData;
};
