import { Box, List, ListItem, Typography } from '@mui/material';
import {
	DelayButton,
	FormAlert,
	FormGroupSection,
	SignAndSubmit,
} from 'module/documents/components/components';
import DynamicFormComponent, {
	GridWrapper,
	FieldRenderer,
} from 'module/documents/components/formTabs/DynamicFormComponent';
import { EmployerFields } from 'module/documents/components/formTabs/EmployerFields';
import { useDocumentContext } from 'module/documents/context';
import { EmployeTippingNotice, EmployeTippingNoticeIndexed } from 'module/documents/data';
import { filterFields } from 'module/documents/utils';

import { FC } from 'react';

const TippingFields = filterFields(EmployeTippingNoticeIndexed, {
	include: ['restaurant_name'],
});

export const EmployeeTippingNotice: FC = () => {
	const { formik, isEmployer } = useDocumentContext();

	return (
		<Box display="flex" flexDirection="column" gap={2}>
			<Typography variant="h4" mb="24px">
				Notice to Tipped Employees
			</Typography>
			<Box mb={2} px="16px" bgcolor="rgb(240, 245, 250)">
				<Box paddingY="16px">
					<Typography variant="body1">
						The U.S. Department of Labor recently amended its tip credit notice regulations
						requiring employers to inform tipped employees of certain tip credit information. We are
						informing you of the following as a result of this new requirement:
					</Typography>
					<List style={{ padding: '16px' }}>
						<ListItem>The amount of cash wage to be paid to you per hour will be $3.93.</ListItem>
						<ListItem>
							Assuming you have received a sufficient amount of tips to cover the tip credit, the
							amount of your tips per hour to be credited as wages will be $6.40, equaling the state
							minimum wage.
						</ListItem>
						<ListItem>
							You have the right to retain all the tips you receive, except for a valid tip pooling
							arrangement limited to employees who customarily and regularly receive tips.
						</ListItem>
						<ListItem>
							The tip credit shall not apply unless you have been informed of these requirements.
						</ListItem>
					</List>
				</Box>
			</Box>

			<FormGroupSection>
				<DynamicFormComponent>
					{TippingFields.map((field, index) => (
						<GridWrapper key={index} field={field} index={index}>
							<FieldRenderer field={field} formik={formik} />
						</GridWrapper>
					))}
				</DynamicFormComponent>
			</FormGroupSection>

			<SignAndSubmit formData={EmployeTippingNotice} onSubmit={console.log} />

			<FormGroupSection show={isEmployer}>
				<FormGroupSection
					title="Employer Information"
					isEmployer={true}
					marginTop="24px"
					marginBottom="12px"
				>
					<EmployerFields fieldValues={['employer_name']} />
				</FormGroupSection>

				<Box paddingY="8px">
					<DelayButton
						variant="contained"
						onClick={() => {
							formik.setFieldValue('signature_approved', true);
						}}
						style={{ width: 'max-content' }}
					>
						Sign employee certification
					</DelayButton>
				</Box>
			</FormGroupSection>
		</Box>
	);
};
