import { useState } from 'react';

interface ModalState {
	[key: string]: boolean;
}

export const useModalManagement = () => {
	const [modalState, setModalState] = useState<ModalState>({});

	const openModal = (modalName: string) => {
		setModalState((prevState) => ({
			...prevState,
			[modalName]: true,
		}));
	};

	const closeModal = (modalName: string) => {
		setModalState((prevState) => ({
			...prevState,
			[modalName]: false,
		}));
	};

	const isModalOpen = (modalName: string): boolean => !!modalState[modalName];

	return {
		isModalOpen,
		openModal,
		closeModal,
	};
};
