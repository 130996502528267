import { Message } from '@chatscope/chat-ui-kit-react';
import { parseISO } from 'date-fns';

import { Box, Button, Typography } from '@mui/material';
import { getBroadcastGroupMessages } from 'core/API/broadcast';
import { useDebouncedValue } from 'core/hooks/useDecbouncedValue';
import { useAuth } from 'module/auth/context/AuthContext';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { formatDate, getChatLikeDate, getColorByAuthor } from '../utils/chat.utils';

import { ArrowDownward } from '@mui/icons-material';
import { differenceInMinutes, isSameDay } from 'date-fns';

const replaceLinksWithAnchorTags = (text: string) => {
	const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
	return text.replace(urlRegex, (url) => {
		return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
	});
};

export const useCountdown = (initialValue: number) => {
	const [count, setCount] = useState(initialValue);

	useEffect(() => {
		if (count > 0) {
			const timer = setInterval(() => {
				setCount((prevCount) => prevCount - 1);
			}, 1000);

			return () => clearInterval(timer);
		}
	}, [count]);

	return count;
};

export const ChatMessageList = ({ activeConversation, ...rest }) => {
	const { workplaceId } = useWorkplacesContext();
	const messageListRef = useRef<HTMLDivElement>(null);

	const [isAtBottom, setIsAtBottom] = useState(true);
	const [hasNewMessages, setHasNewMessages] = useState(false);

	const auth = useAuth();

	const { data, isLoading } = useQuery(
		`broadcast-messages-${activeConversation?.id}`,
		() => getBroadcastGroupMessages(activeConversation?.id, workplaceId),
		{
			enabled: !!activeConversation?.id,
			select: (data) => {
				return data.items;
			},
			refetchOnWindowFocus: false,
			refetchInterval: 1000 * 60 * 0.25, // 1/4 min,
			refetchIntervalInBackground: true,
		}
	);

	useEffect(() => {
		if (messageListRef.current && isAtBottom) {
			messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
		} else if (!isAtBottom) {
			// removed as it triggers when an unactive chat has a new message
			// setHasNewMessages(true);
		}
	}, [data]);

	const handleScroll = () => {
		if (messageListRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
			const isBottom = scrollTop + clientHeight >= scrollHeight - 50; // Buffer of 50px
			setIsAtBottom(isBottom);
			if (isBottom) {
				setHasNewMessages(false);
			}
		}
	};
	const scrollToBottom = () => {
		if (messageListRef.current) {
			messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
			setIsAtBottom(true);
			setHasNewMessages(false);
		}
	};

	const isLoadingDebounced = useDebouncedValue(isLoading, 1000);

	if (isLoadingDebounced) {
		return (
			<div
				as="MessageList"
				className="cs-chat-message-list-override"
				style={{ minHeight: '400px', padding: '20px' }}
				{...rest}
			>
				Loading...
			</div>
		);
	}

	return (
		<div
			ref={messageListRef}
			as="MessageList"
			className="cs-chat-message-list-override"
			style={{ minHeight: '400px', padding: '20px', overflowY: 'auto', position: 'relative' }}
			onScroll={handleScroll}
			{...rest}
		>
			{/* <MessageSeparator content="Saturday, 30 November 2019" /> */}
			{data?.map((message, i) => {
				const currentDate = parseISO(message?.created_at);
				const previousMessageDate = data[i - 1] ? parseISO(data[i - 1]?.created_at) : null;

				const isAuthorMatching = data[i - 1]?.author?.id !== message.author.id;
				const isFirstMessageOfTheDay =
					!previousMessageDate || !isSameDay(currentDate, previousMessageDate);
				const hasSignificantTimeGap =
					previousMessageDate && differenceInMinutes(currentDate, previousMessageDate) > 5;

				const authorName = `${message?.author?.first_name} ${message?.author?.last_name}`;
				const isMessageSent = Boolean(message.id);

				const isMessageIncoming = message?.author?.id !== auth.user.id;

				// Construct the HTML content
				let htmlContent = `<p>${replaceLinksWithAnchorTags(message.content)}</p>`;

				if (message.broadcast_file && message.broadcast_file.length > 0) {
					message.broadcast_file.forEach((file) => {
						try {
							if (file.path.match(/\.(jpeg|jpg|gif|png)$/)) {
								const linkStyle = `display: flex; justify-content: ${
									isMessageIncoming ? 'flex-end' : 'flex-start'
								}`;

								htmlContent += `<a style=${linkStyle} target="_blank" href="${
									process.env.REACT_APP_URL + file.path
								}">
									<img src="${process.env.REACT_APP_URL + file.path}" style="width: 50%; min-width: 100px" alt="${
										file.name
									}" />
								</a>`;
							} else {
								htmlContent += `<a href="${
									process.env.REACT_APP_URL + file.path
								}" target="_blank" rel="noopener noreferrer">${file.name}</a>`;
							}
						} catch (error) {
							console.log('Error rendering message file:', file);
							console.log('error:', error);
						}
					});
				}

				return (
					<Message
						key={message.id}
						model={{
							direction: message?.author?.id === auth.user.id ? 'outgoing' : 'incoming',
							message: message.content,
							position: 'normal',
							sender: message?.author?.first_name,
							sentTime: getChatLikeDate(message?.created_at),
						}}
						className={isMessageSent ? '' : 'message-sending'}
					>
						<Message.HtmlContent html={htmlContent} />
						{isAuthorMatching && (
							<Message.Header
								sender={authorName}
								style={{
									borderRadius: '12px',
									borderTopLeftRadius: '0px',
									borderBottomLeftRadius: '0px',
									borderBottomRightRadius: '0px',
								}}
							>
								<Box>
									<Typography
										style={{
											color: getColorByAuthor(authorName),
											padding: '2px 8px 2px 0px',
											fontWeight: '500',
											fontSize: '0.75rem',
										}}
									>
										{authorName}
									</Typography>
								</Box>
							</Message.Header>
						)}

						{isFirstMessageOfTheDay && (
							<Message.Footer>{formatDate(message?.created_at)}</Message.Footer>
						)}
						{(isFirstMessageOfTheDay || hasSignificantTimeGap) && (
							<Message.Footer>{getChatLikeDate(message?.created_at)}</Message.Footer>
						)}

						{data[i + 1]?.author?.id !== message.author.id && (
							<Message.Footer>{getChatLikeDate(message?.created_at)}</Message.Footer>
						)}
					</Message>
				);
			})}
			{/* If there are no new messages, add text message */}
			{!data?.length && 'No messages yet'}
			{hasNewMessages && (
				<Button
					onClick={scrollToBottom}
					endIcon={<ArrowDownward />}
					style={{
						position: 'fixed',
						top: '10vh',
						right: '30%',
						zIndex: 1000,
						backgroundColor: '#2b6a9b',
						color: 'white',
					}}
				>
					New Messages
				</Button>
			)}
		</div>
	);
};
