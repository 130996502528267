import { FC, useState } from 'react';
// CSS modules
import { SalesNumber } from 'module/laborsaber/interface/Sales';
import moment from 'moment';
import Buttons from 'styles/buttons.module.scss';
import Sections from '../sections.module.scss';

import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import ClipLoader from 'react-spinners/ClipLoader';

interface iNetSalse {
	isLoading: boolean;
	sales: SalesNumber;
}

const DailyLaborCost: FC<iNetSalse> = ({ isLoading, sales }) => {
	const { timezone } = useWorkplacesContext();
	const [toggle, setToggle] = useState(true);

	return (
		<article
			style={{
				width: '100%',
				alignSelf: 'baseline',
				borderRadius: '8px',
				padding: '24px',
				boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.1)',
				background: '#ffffff',
				gridArea: 'dailyLaborCost',
			}}
		>
			{isLoading ? (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						height: '400px',
					}}
				>
					<ClipLoader color="#841D80" loading={true} size={50} />
				</div>
			) : (
				<>
					<div className={Sections.header}>
						<h4
							style={{
								marginBottom: '20px',
							}}
						>
							Daily Labor Cost
						</h4>

						<div>
							<span className={Sections.label}>Data up to</span>
							<span className={Sections.labelDarker}>{moment.tz(timezone).format('HH:mm A')}</span>
						</div>
					</div>
					<div>
						<span className={Sections.label}>Compare to same day</span>
						<div className={Buttons.btnToggle}>
							<button
								type="button"
								className={toggle ? Buttons.btnToggleActive : Buttons.btnToggleDefault}
								onClick={() => setToggle((prevValue) => !prevValue)}
								style={{ width: '50%' }}
							>
								Today
							</button>
							<button
								type="button"
								className={!toggle ? Buttons.btnToggleActive : Buttons.btnToggleDefault}
								onClick={() => setToggle((prevValue) => !prevValue)}
								style={{ width: '50%' }}
							>
								Last week
							</button>
						</div>
					</div>
					<div className={Sections.body}>
						<div className={Sections.row}>
							<span className={Sections.label}>Projected Labor Cost</span>
							<div className={Sections.rowBody}>
								<span className={Sections.rowContent}>
									${' '}
									{toggle
										? sales.daily_labor_cost.today.projected_labor_cost.toFixed(2)
										: sales.daily_labor_cost.last_week.projected_labor_cost.toFixed(2)}
								</span>
							</div>
						</div>
						<div className={Sections.row}>
							<span className={Sections.label}>Actual Labor Cost</span>
							<div className={Sections.rowBody}>
								<span className={Sections.rowContent}>
									${' '}
									{toggle
										? sales.daily_labor_cost.today.actual_labor_cost.toFixed(2)
										: sales.daily_labor_cost.last_week.actual_labor_cost.toFixed(2)}
								</span>
							</div>
						</div>
					</div>
					<div></div>
				</>
			)}
		</article>
	);
};

export default DailyLaborCost;
