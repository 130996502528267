import { apiService } from 'core/services/apiService';
import { IFiltersData } from 'module/situationRoom/interfaces/filters.interface';

const LIMIT = 500;

interface IUpdatePriority {
	id: number;
	body: {
		priority: {
			id: number;
		};
	};
}

export const fetchAllSituationRooms = async (workplaceId: number, active: number): Promise<any> => {
	const { ...data } = apiService.responseHandler(
		await apiService.get(
			`situation-rooms?workplace=${workplaceId}&active=${active}&limit=${LIMIT}&orderBy=createdAt&direction=DESC`
		)
	);

	return data;
};

export const closeSituationRoom = async (id: number): Promise<any> => {
	const { ...data } = apiService.responseHandler(
		await apiService.put(`situation-rooms/${id}/close`, id)
	);

	return data;
};

export const updatePriority = async (value: IUpdatePriority): Promise<any> => {
	const { ...data } = apiService.responseHandler(
		await apiService.put(`situation-rooms/priority/${value?.id}`, value?.body)
	);

	return data;
};

export const fetchTopPriority = async (workplaceId: number): Promise<any> => {
	const { ...data } = apiService.responseHandler(
		await apiService.get(`situation-rooms/${workplaceId}/priority`)
	);

	return data;
};

export const fetchFilters = async (): Promise<IFiltersData> => {
	const { ...data } = apiService.responseHandler(
		await apiService.get('app-lists', { params: { limit: 50 } })
	);

	return data;
};
