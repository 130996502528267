import styled from '@emotion/styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Alert,
	Box,
	BoxProps,
	Button,
	ButtonProps,
	CircularProgress,
	List,
	ListItem,
	TextField,
	Typography,
} from '@mui/material';
import { useDocumentContext } from 'module/documents/context';
import { FormGroup } from 'module/documents/data';
import { validateForm } from 'module/documents/utils';
import { FC, ReactNode, useMemo, useState } from 'react';

import DoneIcon from '@mui/icons-material/Done';
import { useMe } from 'core/hooks/broadcast';

export const EmployeeConfirmField = ({ onConfirm }) => {
	return (
		<Box display="flex" alignItems="center" justifyContent="space-between">
			<Typography variant="body1">Sign this document</Typography>
			<Button variant="contained" color="primary" onClick={onConfirm}>
				Sign
			</Button>
		</Box>
	);
};

interface FormGroupSectionProps {
	title?: string;
	show?: boolean;
	isEmployer?: boolean;
	children?: ReactNode;
}

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	// @ts-ignore
	backgroundColor: theme.palette.grey[200],
}));

interface DelayButtonProps extends ButtonProps {
	children: React.ReactNode;
	onClick: () => void;
}

export const DelayButton: React.FC<DelayButtonProps> = ({ children, onClick, ...rest }) => {
	const [loading, setLoading] = useState(false);
	const [isSigned, setIsSigned] = useState(false);

	const handleClick = async () => {
		setLoading(true);
		await onClick();
		setTimeout(() => {
			setLoading(false);
			setIsSigned(true);
		}, 800);
	};

	const icon = (() => {
		if (loading) {
			return <CircularProgress color="inherit" size={24} />;
		}

		if (isSigned) {
			return <DoneIcon color="inherit" />;
		}

		return null;
	})();

	return (
		<Button startIcon={icon} onClick={handleClick} disabled={loading} variant="contained" {...rest}>
			{children}
		</Button>
	);
};

export const FormGroupSection: FC<FormGroupSectionProps & BoxProps> = ({
	title,
	show = true,
	children = null,
	isEmployer = false,
	...rest
}) => {
	if (!show) {
		return null;
	}

	return (
		<Box
			sx={{
				bgcolor: isEmployer ? '#b7e2c2a3' : '#e0f7fa',
				p: 2,
				borderRadius: 1,
			}}
			{...rest}
		>
			<Typography variant="h5" mb={1}>
				{title}
			</Typography>
			{children}
		</Box>
	);
};

export const FormAlert = ({ children, ...rest }) => {
	return (
		<Alert severity="warning" {...rest}>
			{children}
		</Alert>
	);
};

export const FieldHelper: FC<{ text: string }> = ({ text, ...rest }) => {
	if (!text) {
		return null;
	}

	return (
		<Box display="flex" alignItems="center" gap={1} mb={1}>
			<Typography variant="body2" color="textSecondary">
				{text}
			</Typography>
		</Box>
	);
};

interface SignAndSubmitProps {
	formData: FormGroup;
	onSubmit?: () => void;
	isSubmitDisabled?: boolean;
	extraValidation?: Array<{ field: string; value: any }>;
}

export const SignAndSubmit: FC<SignAndSubmitProps> = ({ formData, onSubmit, extraValidation }) => {
	const { formik, selectedEmployee } = useDocumentContext();

	const { data: me } = useMe();

	const isActiveUserSelected = selectedEmployee.user.id === me.id;

	console.log('isActiveUserSelected:', isActiveUserSelected);

	const isExtraValid = useMemo(() => {
		// if selectedEmployee is not the active user, disable submission
		// HR cannot alter other peoples forms
		if (!isActiveUserSelected) {
			return false;
		}

		if (extraValidation) {
			return extraValidation.every(({ value }) => Boolean(value));
		}

		return true;
	}, [extraValidation, isActiveUserSelected]);

	const formStatus = useMemo(() => {
		return validateForm(formik, formData);
	}, [formik.values]);

	return (
		<Box display="flex" gap={2} mt={2}>
			<Box display="flex" flexDirection="column" gap={2} flex="1">
				<Box px={2}>
					<TextField
						fullWidth
						label="Signature Date"
						variant="standard"
						type="date"
						value={new Date().toISOString().split('T')[0]}
						disabled={true}
						InputLabelProps={{ shrink: true }}
					/>
				</Box>
				<DelayButton
					variant="contained"
					onClick={onSubmit}
					style={{ width: 'max-content' }}
					disabled={!formStatus.valid || !isExtraValid} // Disables the button if form is invalid or not modified
				>
					Sign and Submit
				</DelayButton>
			</Box>

			<Box flex="1">
				{!formStatus.valid || !isExtraValid ? (
					<FormAlert px={2} severity="error">
						<Typography variant="body2">
							{isActiveUserSelected
								? 'Please complete the following required fields:'
								: 'Cannot edit other employee forms'}
						</Typography>
						<ul>
							{formStatus.missingFields.map((field, index) => (
								<li key={field + index}>
									<Typography variant="body2" color="error">
										{field}
									</Typography>
								</li>
							))}
							{extraValidation
								?.filter((field) => !field.value)
								.map((field) => (
									<li key={field.field}>
										<Typography variant="body2" color="error">
											{field.field}
										</Typography>
									</li>
								))}
							{}
						</ul>
					</FormAlert>
				) : (
					<FormAlert px={2} severity="success">
						Form is valid
					</FormAlert>
				)}
			</Box>
		</Box>
	);
};

export const ListADocuments = () => (
	<Accordion>
		<StyledAccordionSummary
			expandIcon={<ExpandMoreIcon />}
			aria-controls="panel1-content"
			id="panel1-header"
		>
			<Typography variant="body1">List A Documents</Typography>
		</StyledAccordionSummary>
		<AccordionDetails>
			<List>
				<ListItem>U.S. Passport or U.S. Passport Card</ListItem>
				<ListItem>Permanent Resident Card or Alien Registration Receipt Card (Form I-551)</ListItem>
				<ListItem>
					Foreign passport with a temporary I-551 stamp or temporary I-551 printed notation on a
					machine-readable immigrant visa
				</ListItem>
				<ListItem>Employment Authorization Document (Form I-766)</ListItem>
				<ListItem>
					For an individual authorized to work for a specific employer due to their status or
					parole: Foreign passport and Form I-94 or Form I-94A that includes an endorsement of the
					individual&apos;s status or parole.
				</ListItem>
			</List>
		</AccordionDetails>
	</Accordion>
);

export const ListBDocuments = () => (
	<Accordion>
		<StyledAccordionSummary
			expandIcon={<ExpandMoreIcon />}
			aria-controls="panel1-content"
			id="panel1-header"
		>
			<Typography variant="body1">List B Documents</Typography>
		</StyledAccordionSummary>
		<AccordionDetails>
			<List>
				<ListItem>
					Driver&apos;s license or ID card issued by a state or outlying possession of the United
					States, provided it contains a photograph or information such as name, date of birth,
					gender, height, eye color, and address
				</ListItem>
				<ListItem>
					ID card issued by federal, state, or local government agencies or entities, provided it
					contains a photograph or information such as name, date of birth, gender, height, eye
					color, and address
				</ListItem>
				<ListItem>School ID card with a photograph</ListItem>
				<ListItem>Voter&apos;s registration card</ListItem>
				<ListItem>U.S. Military card or draft record</ListItem>
				<ListItem>Military dependent&apos;s ID card</ListItem>
				<ListItem>U.S. Coast Guard Merchant Mariner Card</ListItem>
				<ListItem>Native American tribal document</ListItem>
				<ListItem>Driver&apos;s license issued by a Canadian government authority</ListItem>
				<ListItem>
					For persons under age 18 who are unable to present a document listed above:
					<List>
						<ListItem>School record or report card</ListItem>
						<ListItem>Clinic, doctor, or hospital record</ListItem>
						<ListItem>Day-care or nursery school record</ListItem>
					</List>
				</ListItem>
			</List>
		</AccordionDetails>
	</Accordion>
);
export const ListCDocuments = () => (
	<Accordion>
		<StyledAccordionSummary
			expandIcon={<ExpandMoreIcon />}
			aria-controls="panel1-content"
			id="panel1-header"
		>
			<Typography variant="body1">List C Documents</Typography>
		</StyledAccordionSummary>
		<AccordionDetails>
			<List>
				<ListItem>
					A Social Security Account Number card (unless it states it is not valid for employment)
				</ListItem>
				<ListItem>
					Certification of Birth Abroad issued by the U.S. Department of State (Form FS-545, Form
					DS-1350, Form FS-240)
				</ListItem>
				<ListItem>
					Original or certified copy of a birth certificate issued by a state, county, or municipal
					authority
				</ListItem>
				<ListItem>Native American tribal document</ListItem>
				<ListItem>U.S. Citizen ID Card (Form I-197)</ListItem>
				<ListItem>
					Identification Card for Use of Resident Citizen in the United States (Form I-179)
				</ListItem>
				<ListItem>
					Employment authorization document issued by the Department of Homeland Security
				</ListItem>
			</List>
		</AccordionDetails>
	</Accordion>
);
