import { Autocomplete, Chip, Stack, TextField } from '@mui/material';
import { useWorkplaces } from 'core/hooks/broadcast';
import { Workplace } from 'core/interfaces/userInterface';
import { FC, SyntheticEvent, useEffect, useMemo, useRef } from 'react';
import { useBroadcastGroup } from '../../utils/BroadcastGroupProvider';
import { AllCheckbox } from './AllCheckbox';

const WorkplaceAutocomplete: FC = () => {
	const textFieldRef = useRef<HTMLInputElement>(null);

	const { setBroadcastGroup, broadcastGroup } = useBroadcastGroup();

	const {
		data: workplaces,
		isLoading,
		isFetching,
	} = useWorkplaces(broadcastGroup.operating_unit.map((ou) => ou.id));

	useEffect(() => {
		if (workplaces && broadcastGroup.workplace) {
			setBroadcastGroup((prev) => ({
				...prev,
				workplace: prev.workplace.map(
					(workplace) => workplaces.items.find((r) => r.id === workplace.id) || workplace
				),
			}));
		}
	}, [workplaces]);

	const handleWorkplaceChange = (event: SyntheticEvent, newValue: Workplace[]) => {
		setBroadcastGroup((prev) => ({
			...prev,
			workplace: newValue,
		}));
		if (textFieldRef.current) {
			textFieldRef.current.value = '';
		}
	};

	const handleDelete = (wpToDelete: Workplace) => () => {
		setBroadcastGroup((prev) => ({
			...prev,
			workplace: (prev.workplace || []).filter((workplace) => workplace.id !== wpToDelete.id),
		}));
	};

	const onAllToggle = (e) => {
		if (e.target.checked) {
			setBroadcastGroup((prev) => ({
				...prev,
				workplace: workplaces?.items || [],
			}));
		} else {
			setBroadcastGroup((prev) => ({
				...prev,
				workplace: [],
			}));
		}
	};

	const filteredOptions = useMemo(() => {
		return workplaces?.items
			.map((workplace) => ({
				...workplace,
				name: workplace.name,
			}))
			.filter(
				(role) => !broadcastGroup.workplace?.some((selectedRole) => selectedRole.id === role.id)
			);
	}, [workplaces, broadcastGroup.workplace]);

	const value = broadcastGroup.workplace;

	return (
		<Stack spacing={2} direction="row" alignItems="center">
			<AllCheckbox isDisabled={isLoading || isFetching} onChange={onAllToggle} />
			<Autocomplete
				disabled={isLoading || isFetching}
				multiple
				disablePortal
				value={value}
				disableCloseOnSelect
				loading={isLoading}
				onChange={handleWorkplaceChange}
				id="combo-box-demo"
				options={filteredOptions || []}
				getOptionLabel={(option) => `${option.name}`}
				sx={{ width: '100%' }}
				renderTags={(value, getTagProps) =>
					value.map((option, index) => (
						<Chip
							key={index}
							variant="outlined"
							label={option.name}
							{...getTagProps({ index })}
							onDelete={handleDelete(option)}
							sx={{ margin: 0.5, backgroundColor: '#2196f3', color: 'white' }}
						/>
					))
				}
				renderInput={(params) => (
					<TextField {...params} inputRef={textFieldRef} label="Workplaces" />
				)}
			/>
		</Stack>
	);
};

export default WorkplaceAutocomplete;
