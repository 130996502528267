import { getBroadcastGroups, setBroadcastGroupMessageCounts } from 'core/API/broadcast';
import { getTokenFromLocalStorage } from 'core/services/axiosInterceptors';
import { useAuth } from 'module/auth/context/AuthContext';
import { BroadcastGroup } from 'module/laborsaber/components/Chat/utils/BroadcastGroupProvider';

import { useEffect, useState } from 'react';
import { IPaginatedResponse } from 'types';

export const BroadcastInitProvider = ({ children }) => {
	const [initialized, setInitialized] = useState(false);

	const auth = useAuth();

	const isLoggedin = auth.isLoggedin();

	useEffect(() => {
		const workplaceId = getTokenFromLocalStorage('workplace');

		if (!isLoggedin) {
			return children;
		}

		if (!workplaceId || initialized) {
			return;
		}

		getBroadcastGroups(parseInt(workplaceId), 500).then(
			(response: IPaginatedResponse<BroadcastGroup>) => {
				const messageCounts = response.items.reduce((acc, broadcastGroup) => {
					acc[broadcastGroup.id] = broadcastGroup.number_of_messages;
					return acc;
				}, {});

				setBroadcastGroupMessageCounts(messageCounts);
				setInitialized(true);
			}
		);
	}, [isLoggedin]);

	return initialized ? children : null;
};
