import { apiService } from 'core/services/apiService';
import { EmployeeAttendance } from 'module/laborsaber/interface/EmployeeAtendance';
import { EmployeePerformance } from 'module/laborsaber/interface/EmployeePerformance';
import { GraphSales } from 'module/laborsaber/interface/GraphSales';
import { Sales } from 'module/laborsaber/interface/Sales';
import { SituationRoom } from 'module/laborsaber/interface/SituationRoom';

interface iEmployeeProps {
	workplaceId: number;
	formatedFirstDay: string;
	formatedLastDay: string;
	activeTime?: string;
}

const GetEmployeeAttendance = async ({
	workplaceId,
	formatedFirstDay,
	formatedLastDay,
}: iEmployeeProps): Promise<EmployeeAttendance> => {
	const { ...data } = apiService.responseHandler(
		await apiService.get(
			`employee-attendances?limit=250&workplace=${workplaceId}&shift_from=${formatedFirstDay}&shift_to=${formatedLastDay}`
		)
	);

	return data;
};

const GetEmployeePerformance = async ({
	workplaceId,
	formatedFirstDay,
	formatedLastDay,
}: iEmployeeProps): Promise<EmployeePerformance> => {
	const { ...data } = apiService.responseHandler(
		await apiService.get(
			`employee-performance?limit=250&workplace=${workplaceId}&ticket_from=${formatedFirstDay}&ticket_to=${formatedLastDay}`
		)
	);

	return data;
};

const GetGraphSales = async ({
	workplaceId,
	formatedFirstDay,
	formatedLastDay,
}: iEmployeeProps): Promise<GraphSales> => {
	const { ...data } = apiService.responseHandler(
		await apiService.get(
			`labor-saber-sales?limit=250&workplace=${workplaceId}&sales_from=${formatedFirstDay}&sales_to=${formatedLastDay}&orderBy=timeSlot&direction=ASC`
		)
	);

	return data;
};

const GetSalesInfo = async ({
	workplaceId,
	formatedFirstDay,
	formatedLastDay,
	activeTime,
}: iEmployeeProps): Promise<Sales> => {
	const { ...data } = apiService.responseHandler(
		await apiService.get(
			`labor-saber-sales/daily/labor?limit=250&workplace=${workplaceId}&time_slot_from=${formatedFirstDay}&time_slot_to=${formatedLastDay}&data_up_to=${activeTime}&billable=1&predicted=1`
		)
	);

	return data;
};

export const GetSalesDailyLabor = async (
	workplaceId,
	formatedFirstDay,
	formatedLastDay,
	activeTime
): Promise<Sales> => {
	const { ...data } = apiService.responseHandler(
		await apiService.get(
			`labor-saber-sales/daily/labor?workplace=${workplaceId}&time_slot_from=${formatedFirstDay}&time_slot_to=${formatedLastDay}&data_up_to=${activeTime}&billable=1?predicted=1`
		)
	);

	return data;
};

const GetLaborsaborCalculation = async ({
	workplaceId,
	formatedFirstDay,
	formatedLastDay,
}: iEmployeeProps): Promise<SituationRoom> => {
	const { ...data } = apiService.responseHandler(
		await apiService.get(
			`labor-saber-sales/situation-room?limit=250&workplace=${workplaceId}&from=${formatedFirstDay}&to=${formatedLastDay}`
		)
	);

	return data;
};

export {
	GetEmployeeAttendance,
	GetEmployeePerformance,
	GetGraphSales,
	GetSalesInfo,
	GetLaborsaborCalculation,
};
