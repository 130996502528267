import {
	Participant,
	getBroadcastGroups,
	getDivisions,
	getMe,
	getOperatingUnits,
	getRoles,
	getWorkplaces,
} from 'core/API/broadcast';
import { Role } from 'core/API/roles';
import { GetParticipantsList, GetUsersList } from 'core/API/users';
import { Division } from 'core/interfaces/userInterface';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { IPaginatedResponse, UserMe } from 'types';

export const useWorkspaceResources = (incomingWorkplace?: number) => {
	const { workplaceId } = useWorkplacesContext();

	return useQuery(
		['users', incomingWorkplace || workplaceId],
		() => GetUsersList(incomingWorkplace || workplaceId),
		{
			staleTime: Infinity,
			refetchIntervalInBackground: false,
			refetchOnMount: false,
		}
	);
};

export const useRoles = () => {
	return useQuery<IPaginatedResponse<Role>>('broadcast/roles', getRoles, {
		staleTime: Infinity,
		refetchIntervalInBackground: false,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
	});
};

export const useDivision = () => {
	return useQuery<IPaginatedResponse<Division>>('broadcast/divisions', getDivisions, {
		staleTime: Infinity,
		refetchIntervalInBackground: false,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
	});
};

export const useWorkplaces = (searchByOperatingUnits?: number[]) => {
	return useQuery<IPaginatedResponse<any>>(
		['broadcast/workplaces', searchByOperatingUnits],
		() => getWorkplaces(searchByOperatingUnits),
		{
			staleTime: Infinity,
			refetchIntervalInBackground: false,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		}
	);
};

export const useBroadcastGroups = (workplaceId: number, limit: number = 20) => {
	const [intervalTime, setIntervalTime] = useState(5000); // Start at 5 seconds

	const query = useQuery(
		['broadcast-groups', workplaceId, limit],
		() => getBroadcastGroups(workplaceId, limit),
		{
			select: (data) => {
				return data.items;
			},
			staleTime: Infinity,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		const interval = setInterval(() => {
			if (query.data?.length > 0) {
				query.refetch();
				setIntervalTime(5000); // Reset interval to 5 seconds if there is new data
			} else if (intervalTime < 60000) {
				setIntervalTime((prev) => Math.min(prev + 5000, 60000)); // Increment by 5 seconds, max 1 minute
			}
		}, intervalTime);

		return () => clearInterval(interval);
	}, [query, intervalTime]);

	return query;
};

export const useParticipants = () => {
	const { workplaceId } = useWorkplacesContext();

	return useQuery<Participant[]>(
		['broadcast/participants', workplaceId],
		() => GetParticipantsList(workplaceId),
		{
			staleTime: Infinity,
			enabled: !!workplaceId,
			refetchIntervalInBackground: false,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		}
	);
};

export const useOperatingUnits = (searchByDivision?: number[]) => {
	return useQuery<IPaginatedResponse<any>>(
		['broadcast/operating-units', searchByDivision],
		() => getOperatingUnits(searchByDivision),
		{
			staleTime: Infinity,
			refetchIntervalInBackground: false,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		}
	);
};
// Custom hook to use the /me endpoint
export const useMe = () => {
	return useQuery<UserMe>('me', getMe, {
		staleTime: Infinity,
		refetchOnWindowFocus: false,
	});
};
