import NotificationsIcon from '@mui/icons-material/Notifications';
import { Box, Button, IconButton, Link, Popover, Tooltip, Typography } from '@mui/material';
import { PutNotifications } from 'core/API/notification';
import { Notification, NotificationItem } from 'core/interfaces/notificationInterface';
import { useAuth } from 'module/auth/context/AuthContext';
import * as React from 'react';
import { useMutation } from 'react-query';

export default function NotificationsMenu({ data }: { data: Notification }) {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const auth = useAuth();
	const open = Boolean(anchorEl);

	const { mutate } = useMutation(PutNotifications);

	const markAsReadHandler = () => {
		data?.items.forEach((value) => {
			const sendObj = {
				notification: value.id,
				content: {
					user: {
						id: auth.user.id,
					},
					active: false,
					readed: true,
				},
			};
			mutate(sendObj);
		});
	};

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<Tooltip title={data?.total_results === 0 ? "No new notifications" : ""}>
				<Box>
					<IconButton onClick={handleClick} color="primary" disabled={data?.total_results === 0}>
						<NotificationsIcon fontSize="large" />
					</IconButton>
				</Box>
			</Tooltip>

			<Popover
				id="notifications-menu"
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Box display="flex" flexDirection="column" padding={1}>
					<Box display="flex" justifyContent="flex-end">
						<Link fontSize="12px" as={Button} onClick={markAsReadHandler} display="block">
							Mark all as read
						</Link>
					</Box>
					{data?.items?.map((value: NotificationItem) => {
						return (
							<Box key={value.id} padding={1}>
								<Typography variant="body1" as="span" paddingX={3}>
									🔔 {value.message}
								</Typography>
							</Box>
						);
					})}
				</Box>
			</Popover>
		</div>
	);
}
