import { apiService } from 'core/services/apiService';

import { Notification } from 'core/interfaces/notificationInterface';

interface PutNotification {
	notification: number;
	content: Content;
}

export interface Content {
	user: { id: number };
	active: boolean;
	readed: boolean;
}

const GetNotifications = async (): Promise<Notification> => {
	const { ...data } = apiService.responseHandler(await apiService.get(`user-notifications?limit=100&active=1`));

	return data;
};

const PutNotifications = async (body: PutNotification): Promise<any> => {
	const { ...data } = apiService.responseHandler(
		await apiService.put(`user-notifications/${body.notification}`, body.content)
	);

	return data;
};

export { GetNotifications, PutNotifications };
