import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

export function useDebouncedValue<T>(value: T, delay: number): T {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = debounce(() => setDebouncedValue(value), delay);

		handler();

		return () => {
			handler.cancel();
		};
	}, [value, delay]);

	return debouncedValue;
}

export function debounceFunction<T extends (...args: any[]) => void>(
	func: T,
	wait: number
): (...args: Parameters<T>) => void {
	let timeout: ReturnType<typeof setTimeout>;

	return function executedFunction(...args: Parameters<T>) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
}
