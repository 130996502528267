import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
	interface Palette {
		dark: Palette['primary'];
	}
	interface PaletteOptions {
		dark?: PaletteOptions['primary'];
	}
}

export const theme = createTheme({
	palette: {
		primary: {
			main: '#1c1b1c', // Your desired primary color (blue in this example)
		},
		secondary: {
			main: '#841d80', // Your desired primary color (blue in this example)
		},
		info: {
			main: '#4789c8', // Your desired primary color (blue in this example)
		},
		dark: {
			main: '#242105',
			light: '#fff',
			dark: '#242105',
			contrastText: '#fff',
		},
	},
	typography: {
		fontFamily: '"Gotham", sans-serif',
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'none',
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					minWidth: '150px',
					textAlign: 'left',
				},
				indicator: {
					transition: 'none',
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					maxWidth: '270px',
				},
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					paddingTop: '24px',
				},
			},
		},
		MuiListItem: {
			styleOverrides: {
				root: {
					listStyleType: 'disc', // Set the type of bullet you want
					display: 'list-item', // Ensure it's displayed as a list item
					paddingBottom: 0,
					paddingLeft: 0,
				},
			},
		},
		MuiAccordionDetails: {
			styleOverrides: {
				root: {
					// backgroundColor: 'transparent',
					// backgroundColor: 'rgba(240, 245, 250, 0.75)',
					margin: 4,
				},
			},
		},
		MuiCollapse: {
			styleOverrides: {
				root: {
					paddingLeft: 4,
					paddingRight: 4,
				},
			},
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: {
					fontSize: '0.75rem',
					// backgroundColor: 'rgba(240, 245, 250, 1)',
					// backgroundColor: 'transparent',
				},
			},
		},
		MuiButtonBase: {
			styleOverrides: {
				root: {
					alignItems: 'flex-start',
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					paddingTop: '1rem',
					paddingBottom: '1rem',
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				asterisk: {
					color: 'red',
				},
				root: {
					marginBottom: '12px',
					// transform: 'translate(0, -1.5px) scale(0.75)',
				},
				required: {},
			},
		},
		MuiAutocomplete: {
			styleOverrides: {
				inputRoot: {
					maxHeight: '200px',
					overflowY: 'hidden',
				},
			},
		},
	},
});
