import { FC, useState } from 'react';
import moment from 'moment';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import ClipLoader from 'react-spinners/ClipLoader';

import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { useToastContext } from 'core/components/Toast/context/ToastContext';

import {
	fetchAllEventTypes,
	postEvent,
	deleteEvent,
	updateEvent,
	postStaffing,
	deleteStaffing,
	getEventsStaffing,
} from 'core/API/events';
import { fetchAllDomains } from 'core/API/domains';
import { generateTimeArray, generateDatesForNextWeeks } from 'shared/utils/dates';

import { ICellClick } from 'module/schedule/pages/SchedulePage';

import bin from 'assets/icons/Bin.svg';

import Loading from 'core/components/Loading/Loading';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import SvgIcon from 'shared/components/SvgIcon/SvgIcon';

// CSS modules
import Buttons from 'styles/buttons.module.scss';
import Forms from 'styles/forms.module.scss';
import Icons from 'styles/icons.module.scss';
import UploadFile from './UploadFile';

import AddEventModalStyle from './AddEventModal.module.scss';

import { FiTrash2 } from 'react-icons/fi';
import { IRoles } from 'module/preload/interfaces/RolesInterface';

interface IEvent {
	eventName: string;
	startDate: string;
	from: string;
	endDate: string;
	to: string;
	eventType: number;
	numOfAtendees: number;
	location: string;
	organizersName: string;
	organizersEmail: string;
	organizersPhoneNumber: string;
	note: string;
	menu: string;
	banquet_event_order: string;
}

interface IDomain {
	id: number;
	name: string;
}

interface IAddEventModal {
	closeEventModal: () => void;
	editEvent?: any;
	addNewEvent?: ICellClick;
}

const AddEventModal: FC<IAddEventModal> = ({ closeEventModal, editEvent, addNewEvent }) => {
	const { workplaceId, timezone } = useWorkplacesContext();
	const { showToast } = useToastContext();
	const queryClient = useQueryClient();
	const allRoles: IRoles = queryClient.getQueryData('roles');

	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

	const [staffing, setStaffing] = useState([]);

	const { mutateAsync: addStaffing } = useMutation(postStaffing, {
		onSuccess: () => {
			showToast('success', 'Event successfully added!');
			closeEventModal();
		},
	});

	const { mutate, isLoading: isAddPostLoading } = useMutation(postEvent, {
		onSuccess: (data) => {
			//add staffing
			staffing.forEach(async (staff) => {
				const sendObj = {
					event: {
						id: data.id,
					},
					role: {
						id: staff.role.id,
					},
					needed_number_of_staff: staff.needed_number_of_staff,
				};

				await addStaffing(sendObj);
			});
		},
		onError: () => showToast('error'),
	});

	const { data: eventStaffing, isLoading: isEventStaffingLoading } = useQuery(
		['event-staffing'],
		() => getEventsStaffing(editEvent?.id),
		{
			enabled: Boolean(editEvent?.id) || false,
			onSuccess: (response) => {
				const tempArr = [];
				response.items.forEach((item) => {
					tempArr.push({
						name: item.role.name,
						role: {
							id: item.role.id,
						},
						needed_number_of_staff: item.needed_number_of_staff,
					});
				});
				setStaffing(tempArr);
			},
		}
	);

	const { mutateAsync: deleteStaff } = useMutation(deleteStaffing);

	const { mutate: deleteCurrentEvent, isLoading: loadingDeleteEvent } = useMutation(deleteEvent);

	const { mutate: updateCurrentEvent, isLoading: loadingUpdateEvent } = useMutation(updateEvent, {
		onSuccess: () => {
			// update staffing
			eventStaffing.items.forEach(async (item) => {
				await deleteStaff(item.id);
			});

			staffing.forEach(async (staff) => {
				const sendObj = {
					event: {
						id: editEvent.id,
					},
					role: {
						id: staff.role.id,
					},
					needed_number_of_staff: staff.needed_number_of_staff,
				};

				await addStaffing(sendObj);
			});

			showToast('success', 'Event successfully updated!');
			closeEventModal();
		},
		onError: () => showToast('error', 'Error'),
	});

	const { data: eventTypes, isFetching: isFetchingEventTypes } = useQuery(['eventTypes'], () => fetchAllEventTypes(), {
		staleTime: Infinity,
	});

	const { data: domains, isFetching: isFetchingDomains } = useQuery(['domains'], () => fetchAllDomains(workplaceId), {
		staleTime: Infinity,
	});

	const { values, handleChange, submitForm, setFieldValue } = useFormik<IEvent>({
		initialValues: {
			eventName: editEvent?.name || '',
			startDate: editEvent?.start || addNewEvent?.start_at || new Date().toDateString(),
			from: !editEvent
				? moment(addNewEvent?.start_at).format('HH:mm')
				: editEvent?.fromUtc || moment.tz(editEvent?.start, timezone).format('HH:mm'),
			endDate: editEvent?.end || addNewEvent?.start_at || new Date().toDateString(),
			to: !editEvent
				? moment(addNewEvent?.end_at).format('HH:mm')
				: editEvent?.toUtc || moment.tz(editEvent?.end, timezone).format('HH:mm'),
			eventType: editEvent?.resource || addNewEvent?.resource || '',
			numOfAtendees: editEvent?.size || 1,
			location: editEvent?.location || '',
			organizersName: editEvent?.organizer_full_name || '',
			organizersEmail: editEvent?.organizer_email || '',
			organizersPhoneNumber: editEvent?.organizer_phone_number || '',
			note: editEvent?.note || '',
			menu: editEvent?.menu || '',
			banquet_event_order: editEvent?.banquet_event_order || '',
		},
		onSubmit: (data) => {
			const startDate = new Date(data?.startDate);
			startDate.setHours(parseInt(data?.from.split(':')[0]));
			startDate.setMinutes(parseInt(data?.from.split(':')[1]));
			startDate.setSeconds(parseInt('00'));

			const endDate = new Date(data?.endDate);
			endDate.setHours(parseInt(data?.to.split(':')[0]));
			endDate.setMinutes(parseInt(data?.to.split(':')[1]));
			endDate.setSeconds(parseInt('00'));

			const fmt = 'MM/DD/YYYY HH:mm:ss';

			const newStartFormated = moment(startDate).format(fmt);
			const newEndFormated = moment(endDate).format(fmt);

			const newStart_at = moment.tz(newStartFormated, fmt, timezone).utc().toDate().toISOString();

			const newEnd_at = moment.tz(newEndFormated, fmt, timezone).utc().toDate().toISOString();

			const newEvent = {
				workplace: {
					id: workplaceId,
				},
				name: data?.eventName,
				// start: moment(startDate).format('YYYY-MM-DDTHH:mm:ssZ'),
				// end: moment(endDate).format('YYYY-MM-DDTHH:mm:ssZ'),
				start: newStart_at.substring(0, newStart_at.length - 5),
				end: newEnd_at.substring(0, newEnd_at.length - 5),
				size: data?.numOfAtendees,
				location: data?.location,
				event_type: {
					id: Number.parseInt(`${data?.eventType}`),
				},
				organizer_full_name: data?.organizersName,
				organizer_email: data?.organizersEmail,
				organizer_phone_number: data?.organizersPhoneNumber,
				note: data?.note,
				menu: data?.menu,
				banquet_event_order: data?.banquet_event_order,
			};

			if (!editEvent) {
				mutate(newEvent);
			} else {
				const updateEvent = {
					id: editEvent?.id,
					body: { ...newEvent },
				};

				updateCurrentEvent(updateEvent);
			}
		},
	});

	const handleDeleteClick = () => {
		deleteCurrentEvent(editEvent?.id, {
			onSuccess: () => {
				showToast('success', 'Event deleted successfully!');
				closeEventModal();
			},
			onError: () => {
				showToast('error');
			},
		});
	};

	const handleSubmitForm = (e: any) => {
		e.preventDefault();
		submitForm();
	};

	const deleteStaffHandler = (index: number) => {
		const oldStaff = [...staffing].filter((_, staffIndex) => staffIndex !== index);
		setStaffing(oldStaff);
	};

	return isFetchingEventTypes && isFetchingDomains && isEventStaffingLoading ? (
		<Loading />
	) : (
		<form className={Forms.formWrapper} onSubmit={(e) => handleSubmitForm(e)}>
			<div className={Forms.formGroup}>
				<label className={Forms.formLabel} htmlFor="eventName">
					Name of the event
				</label>
				<input
					className={Forms.formInputDefault}
					type="text"
					name="eventName"
					id="eventName"
					placeholder="Name event"
					value={values.eventName}
					onChange={handleChange}
					required
				/>
			</div>
			<div className={Forms.formGroupSplitUnevenly}>
				<div className={Forms.formGroup}>
					<label className={Forms.formLabel} htmlFor="startDate">
						Start date
					</label>
					<select
						className={Forms.formSelect}
						name="startDate"
						id="startDate"
						value={values?.startDate}
						onChange={handleChange}
					>
						<option value="" disabled>
							Choose start date
						</option>
						{generateDatesForNextWeeks(editEvent?.start || addNewEvent?.start_at || new Date().toDateString(), 2)?.map(
							(date, index) => (
								<option key={index} value={date.toDateString()}>
									{moment(date).format('dddd, D MMMM YYYY')}
								</option>
							)
						)}
					</select>
				</div>
				<div className={Forms.formGroup}>
					<label className={Forms.formLabel} htmlFor="from">
						From
					</label>
					<select className={Forms.formSelect} name="from" id="from" value={values?.from} onChange={handleChange}>
						{generateTimeArray().map((time, index) => (
							<option key={index} value={time}>
								{time}
							</option>
						))}
					</select>
				</div>
			</div>
			<div className={Forms.formGroupSplitUnevenly}>
				<div className={Forms.formGroup}>
					<label className={Forms.formLabel} htmlFor="endDate">
						End date
					</label>
					<select
						className={Forms.formSelect}
						name="endDate"
						id="endDate"
						value={values?.endDate}
						onChange={handleChange}
					>
						<option value="" disabled>
							Choose end date
						</option>
						{generateDatesForNextWeeks(editEvent?.start || addNewEvent?.start_at || new Date().toDateString(), 2)?.map(
							(date, index) => (
								<option key={index} value={date.toString()}>
									{moment(date).format('dddd, D MMMM YYYY')}
								</option>
							)
						)}
					</select>
				</div>
				<div className={Forms.formGroup}>
					<label className={Forms.formLabel} htmlFor="to">
						To
					</label>
					<select className={Forms.formSelect} name="to" id="to" value={values?.to} onChange={handleChange}>
						{generateTimeArray().map((time, index) => (
							<option key={index} value={time}>
								{time}
							</option>
						))}
					</select>
				</div>
			</div>
			<div className={Forms.formGroupSplitUnevenly}>
				<div className={Forms.formGroup}>
					<label className={Forms.formLabel} htmlFor="eventType">
						Type of event
					</label>
					<select
						className={Forms.formSelect}
						name="eventType"
						id="eventType"
						value={values?.eventType}
						onChange={handleChange}
						required
					>
						<option value="" disabled>
							Choose event type
						</option>
						{eventTypes?.items?.map((eventType: any) => (
							<option key={eventType.id} value={eventType.id}>
								{eventType.type}
							</option>
						))}
					</select>
				</div>
				<div className={Forms.formGroup}>
					<label className={Forms.formLabel} htmlFor="atendees">
						No of atendees
					</label>
					<div className={Forms.formCounter}>
						<button
							type="button"
							onClick={() => values?.numOfAtendees > 1 && setFieldValue('numOfAtendees', values?.numOfAtendees - 1)}
						>
							-
						</button>
						<input
							type="number"
							name="numOfAtendees"
							id="numOfAtendees"
							value={values?.numOfAtendees}
							min={1}
							onChange={(e) => setFieldValue('numOfAtendees', Number.parseInt(e.target.value))}
						/>
						<button type="button" onClick={() => setFieldValue('numOfAtendees', values?.numOfAtendees + 1)}>
							+
						</button>
					</div>
				</div>
			</div>

			<div className={Forms.formGroupSplitUnevenly}>
				<div className={Forms.formGroup}>
					<label className={Forms.formLabel} htmlFor="eventType">
						Select Staffing
					</label>
					{staffing.map((staff, index) => (
						<div className={AddEventModalStyle.staffingRow} key={index}>
							<div onClick={() => deleteStaffHandler(index)}>
								<FiTrash2 size={20} color="red" />
							</div>
							<span>{staff.name}</span>
						</div>
					))}
				</div>
				<div className={Forms.formGroup}>
					<label className={Forms.formLabel} htmlFor="atendees">
						No of staff
					</label>
					{staffing.map((staff, index) => (
						<div className={Forms.formCounter} key={index}>
							<button
								type="button"
								onClick={() => {
									const oldStaff = [...staffing];
									if (oldStaff[index].needed_number_of_staff !== 1) {
										oldStaff[index].needed_number_of_staff -= 1;
									}
									setStaffing(oldStaff);
								}}
							>
								-
							</button>
							<input
								type="number"
								name="numOfAtendees"
								id="numOfAtendees"
								min={1}
								value={staff?.needed_number_of_staff}
								disabled
							/>
							<button
								type="button"
								onClick={() => {
									const oldStaff = [...staffing];
									oldStaff[index].needed_number_of_staff += 1;
									setStaffing(oldStaff);
								}}
							>
								+
							</button>
						</div>
					))}
				</div>
			</div>

			<div className={Forms.formGroup}>
				<select
					className={Forms.formSelect}
					name="select_role"
					id="select_role"
					value=""
					onChange={(value) => {
						const roleName = allRoles.items.filter((role) => role.id === +value.target.value);
						const oldStaff = [...staffing];
						const ifIndex = oldStaff.findIndex((staff) => staff.role.id === value.target.value);
						if (ifIndex >= 0) {
							oldStaff[ifIndex].needed_number_of_staff += 1;
							setStaffing(oldStaff);
						} else {
							setStaffing((prevValue) => [
								...prevValue,
								{
									name: roleName[0].name || '',
									role: {
										id: value.target.value,
									},
									needed_number_of_staff: 1,
								},
							]);
						}
					}}
				>
					<option value="" disabled>
						Choose role
					</option>
					{allRoles.items.map((role) => {
						return (
							<option value={role.id} key={role.id}>
								{role.name}
							</option>
						);
					})}
				</select>
			</div>

			<div className={Forms.formGroup}>
				<label className={Forms.formLabel} htmlFor="location">
					Location
				</label>
				<select
					className={Forms.formSelect}
					name="location"
					id="location"
					value={values?.location}
					onChange={handleChange}
				>
					<option value="" disabled>
						Choose location
					</option>
					{domains?.items?.map(({ id, name }: IDomain) => (
						<option key={id} value={name}>
							{name}
						</option>
					))}
				</select>
			</div>
			<div className={Forms.formGroup}>
				<label className={Forms.formLabel} htmlFor="organizersName">
					Requester&apos;s full name
				</label>
				<input
					className={Forms.formInputDefault}
					type="text"
					name="organizersName"
					id="organizersName"
					placeholder="Requester's full name"
					value={values.organizersName}
					onChange={handleChange}
				/>
			</div>
			<div className={Forms.formGroup}>
				<label className={Forms.formLabel} htmlFor="organizersEmail">
					Requester&apos;s email
				</label>
				<input
					className={Forms.formInputDefault}
					type="text"
					name="organizersEmail"
					id="organizersEmail"
					placeholder="Requester's email"
					value={values.organizersEmail}
					onChange={handleChange}
				/>
			</div>
			<div className={Forms.formGroup}>
				<label className={Forms.formLabel} htmlFor="organizersPhoneNumber">
					Requester&apos;s phone number
				</label>
				<input
					className={Forms.formInputDefault}
					type="text"
					name="organizersPhoneNumber"
					id="organizersPhoneNumber"
					placeholder="Requester's phone number"
					value={values.organizersPhoneNumber}
					onChange={handleChange}
				/>
			</div>
			<div className={Forms.formGroup}>
				<label className={Forms.formLabel} htmlFor="textarea">
					Note
				</label>
				<textarea
					className={Forms.formTextarea}
					name="note"
					id="textarea"
					placeholder="Write note here"
					maxLength={160}
					value={values.note}
					onChange={handleChange}
				></textarea>
			</div>
			<div className={Forms.formGroup}>
				<label className={Forms.formLabel}>Upload menu</label>
				<UploadFile setFieldValue={setFieldValue} fieldName="menu" value={values.menu} />
			</div>
			<div className={Forms.formGroup}>
				<label className={Forms.formLabel}>Upload banquet event order</label>
				<UploadFile setFieldValue={setFieldValue} fieldName="banquet_event_order" value={values.banquet_event_order} />
			</div>
			<footer className={editEvent ? Forms.formFooterSplit : Forms.formFooter}>
				{loadingUpdateEvent || isAddPostLoading ? (
					<ClipLoader color="#841D80" loading={true} size={50} />
				) : (
					<>
						{editEvent ? (
							<button type="button" className={Buttons.btnDelete} onClick={() => setIsDialogOpen(true)}>
								<SvgIcon spriteUrl={bin} className={Icons.btnErrorIcon} />
								Delete event
							</button>
						) : (
							<button type="button" className={Buttons.btnText} onClick={closeEventModal}>
								Cancel
							</button>
						)}
						<button type="submit" className={Buttons.btnPrimary}>
							Save event
						</button>
					</>
				)}
			</footer>
			{isDialogOpen && (
				<ConfirmDialog
					type="event"
					isLoading={loadingDeleteEvent}
					handleDeleteClick={handleDeleteClick}
					setConfirmDeleteIsOpen={setIsDialogOpen}
				/>
			)}
		</form>
	);
};

export default AddEventModal;
