import ClipLoader from 'react-spinners/ClipLoader';
import DialogStyle from './ConfirmDialog.module.scss';
import Buttons from 'styles/buttons.module.scss';

interface IConfirmDialog {
	type: 'shift' | 'event';
	isLoading: boolean;
	handleDeleteClick: () => void;
	setConfirmDeleteIsOpen: (args: boolean) => void;
}

export default function ConfirmDialog({
	type,
	isLoading,
	handleDeleteClick,
	setConfirmDeleteIsOpen,
}: IConfirmDialog): JSX.Element {
	return (
		<div className={DialogStyle.dialogWrapper}>
			<div className={DialogStyle.dialogContent}>
				<h5>Delete {type === 'shift' ? 'shift' : 'event'}?</h5>
				<p>Are you sure you want to delete this {type === 'shift' ? 'shift' : 'event'}?</p>
				{isLoading ? (
					<ClipLoader color="#841D80" loading={true} size={50} />
				) : (
					<footer>
						<button onClick={handleDeleteClick} className={Buttons.btnPrimary}>
							Yes, delete
						</button>
						<button className={Buttons.btnText} onClick={() => setConfirmDeleteIsOpen(false)}>
							Cancel
						</button>
					</footer>
				)}
			</div>
		</div>
	);
}
