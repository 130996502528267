import generateTimeArray from './generateTimeArray';
import generateDatesForNextWeeks from './generateDatesForNextWeeks';
import toIsoString from './toIsoString';
import generateTimeString from './generateTimeString';
import formatTime from './formatTime';
import formatDate from './formatDate';
import moment from 'moment';

function getDayNumber(day) {
	// Create a moment object for any arbitrary week
	const momentDay = moment().day(day);
	// Moment.js uses Sunday as 0, so adjust accordingly
	if (!momentDay.isValid()) {
		return null; // Return null if the day name is not valid
	}
	// Make Monday start as 1 and Sunday as 7
	return momentDay.isoWeekday();
}
export {
	getDayNumber,
	generateTimeArray,
	generateDatesForNextWeeks,
	toIsoString,
	generateTimeString,
	formatTime,
	formatDate,
};
