import { apiService } from 'core/services/apiService';
import { IWorkplaces } from 'module/workplaces/interface/WorkplacesInterface';
import { IWorkplacesAll } from 'module/preload/interfaces/WorkplacesAllInterface';
import { IGuestProtect } from 'module/guestprotect/interface/IGuestProtect';
import { IGuestConnect } from 'module/guestconnect/interface/IGuestConnect';
import { IGuestConnectPost } from 'module/guestconnect/interface/IGuestConnectPost';
import { IGuestProtectPostResponse } from 'module/guestconnect/interface/IGuestPostResponse';
import { IguestCheck } from 'module/guestconnect/interface/IGuestCheck';

const GetWorkplaceList = async (): Promise<IWorkplaces> => {
	const { ...data } = apiService.responseHandler(await apiService.get(`users-workplaces`));

	return data;
};

const GetAllWorkplaceList = async (): Promise<IWorkplacesAll> => {
	const { ...data } = apiService.responseHandler(await apiService.get(`workplaces`));

	return data;
};

const GetSingleWorkplace = async (workplaceId: number): Promise<any> => {
	const { ...data } = apiService.responseHandler(await apiService.get(`workplaces/${workplaceId}`));

	return data;
};

const GetGuestProtect = async (workplaceId: string): Promise<IGuestProtect> => {
	const { ...data } = apiService.responseHandler(
		await apiService.get(`workplaces/guest-connect/${workplaceId}`)
	);

	return data;
};

const PostGuestProtect = async (config: {
	workplaceId: string;
	body: any;
}): Promise<IGuestProtect> => {
	const { ...data } = apiService.responseHandler(
		await apiService.post(`workplaces/guest-connect/${config.workplaceId}`, config.body)
	);

	return data;
};

const GetGuestConnect = async (workplaceId: string): Promise<IGuestConnect> => {
	const { ...data } = apiService.responseHandler(
		await apiService.get(`guest-connect/${workplaceId}/config`)
	);

	return data;
};

const PostGuestConnect = async ({
	workplaceIdentifier,
	domainIdentifier,
	body,
}: {
	workplaceIdentifier: string;
	domainIdentifier: string;
	body: Partial<IGuestConnectPost>;
}): Promise<IGuestProtectPostResponse> => {
	const { ...data } = apiService.responseHandler(
		await apiService.post(`guest-connect/${workplaceIdentifier}/${domainIdentifier}`, body)
	);

	return data;
};
const CheckGuestConnect = async (identifier: string): Promise<IguestCheck> => {
	const { ...data } = apiService.responseHandler(
		await apiService.get(`guest-connect/check/${identifier}`)
	);

	return data;
};

const PutGuestConnect = async (identifier: string): Promise<any> => {
	const { ...data } = apiService.responseHandler(
		await apiService.put(`guest-connect/reward/${identifier}`, {})
	);

	return data;
};

export {
	GetWorkplaceList,
	GetAllWorkplaceList,
	GetSingleWorkplace,
	GetGuestProtect,
	PostGuestProtect,
	GetGuestConnect,
	PostGuestConnect,
	CheckGuestConnect,
	PutGuestConnect,
};
