import { compact } from 'lodash';
import moment from 'moment';

const FORMATDATE = 'YYYY-MM-DD HH:mm:ss';

// calculate business day from timezone
// starts at 5am and ends just before 5am next day
// convert everything to UTC timezone because everything
// in database is stored in UTC

export const objectToQueryParams = (obj) => {
	return compact(
		Object.keys(obj).map((key) => {
			if (!obj[key]) {
				return '';
			}

			return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
		})
	).join('&');
};

export const getBusinessDay = (timezone: string): string[] => {
	const time = moment.tz(timezone);
	const getTime = () => time.clone();

	const startOfDay = getTime().startOf('day').set({ hour: 5, minute: 0, second: 0 }).utc().format(FORMATDATE);
	const endOfDay = moment
		.tz(timezone)
		.startOf('day')
		.add(1, 'day')
		.set({ hour: 4, minute: 59, second: 59 })
		.utc()
		.format(FORMATDATE);

	const activeTime = getTime().utc().format(FORMATDATE);

	if (getTime().isBetween(getTime().startOf('day'), getTime().startOf('day').add(5, 'hours'))) {
		return [
			getTime().subtract(1, 'day').set({ hour: 5, minute: 0, second: 0 }).utc().format(FORMATDATE),
			getTime().set({ hour: 4, minute: 59, second: 59 }).utc().format(FORMATDATE),
			activeTime,
		];
	}

	return [startOfDay, endOfDay, activeTime];
};

export const getBusinessWeek = (time: moment.Moment): string[] => {
	// If current time is before Monday 05:00, consider the current week, else consider the next week
	const startOfWeek =
		time.isoWeekday() === 1 && time.hour() < 5
			? time.clone().startOf('isoWeek').subtract({ days: 7 }).add({ hours: 5 })
			: time.clone().startOf('isoWeek').add({ hours: 5 });

	// Adjust end of the week to next Monday at 04:59
	const endOfWeek =
		time.isoWeekday() === 1 && time.hour() < 5
			? time.clone().startOf('day').add({ hours: 4, minutes: 59, seconds: 59 })
			: time.clone().endOf('isoWeek').add({ hours: 4, minutes: 59, seconds: 59 });

	return [startOfWeek.format(FORMATDATE), endOfWeek.format(FORMATDATE)];
};
